import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'

import Divider from 'components/Divider'
import useAuthentication from 'hooks/useAuthentication'
import useBreakpoint, {
  Breakpoint,
  PHONE_BREAKPOINTS,
  TABLET_BREAKPOINT,
} from 'hooks/useBreakPoint'
import { colors } from 'config/constants'

export interface ProductCardProps {
  type: 'small' | 'large'
  imgSrc: string
  title: string
  price: number
  productUrl: string
  isLiked?: boolean
  siteName: string
  hideLike?: boolean
  onLike?: Function
  placeholder?: boolean
}

const ProductCard = (props: ProductCardProps) => {
  const br = useBreakpoint()
  const { unauthenticated, setShowLogInModal } = useAuthentication()

  const {
    type,
    imgSrc,
    title,
    price,
    productUrl,
    isLiked,
    siteName,
    hideLike = false,
    onLike = () => {},
    placeholder = false,
  } = props

  const [isProductLiked, setIsProductLiked] = useState(isLiked || false)

  const toggleIsLiked = useCallback(() => {
    if (unauthenticated) {
      setShowLogInModal(true)
      return
    }

    setIsProductLiked(!isProductLiked)
    onLike(!isProductLiked)
  }, [isProductLiked, onLike, setShowLogInModal, unauthenticated])

  const formattedPrice = useMemo(() => {
    if (placeholder) return 'empty'
    return `£${price}`
  }, [price, placeholder])

  return (
    <ProductCardContainer breakpoint={br}>
      {!hideLike && (
        <IconContainer onClick={toggleIsLiked}>
          <StyledIcon
            icon="heart"
            color="white"
            size={24}
            {...(isProductLiked && { fill: 'white' })}
          />
        </IconContainer>
      )}
      <Image br={br} imgSrc={imgSrc} title={title} />
      <ProductDetailsContainer breakpoint={br}>
        <ProductCardTitle type={type} isPlaceholder={placeholder}>
          {title}
        </ProductCardTitle>
        {type === 'large' && <Divider />}
        <ProductDetailsInnerContainer breakpoint={br}>
          {price === 0 && !placeholder ? (
            <StyledPrice>Free</StyledPrice>
          ) : (
            <ProductCardPrice type={type} isPlaceholder={placeholder}>
              {formattedPrice}
            </ProductCardPrice>
          )}
          <ProductCardLink type={type}>{siteName}</ProductCardLink>
        </ProductDetailsInnerContainer>
      </ProductDetailsContainer>
    </ProductCardContainer>
  )
}

/**
 * Image component - breaking ternary expression since it was hard to read
 * and re-enabling eslint to fix dependency problems
 *
 * @param param0
 * @returns
 */
function Image({
  imgSrc,
  br,
  title,
}: {
  imgSrc?: string
  br: Breakpoint
  title: string
}) {
  if (!imgSrc) {
    return (
      <ProductCardImagePlaceholder
        breakpoint={br}
        className="d-flex align-items-center justify-content-center"
      >
        <FeatherIcon icon="image" color="#C4C4C4" size={42} />
      </ProductCardImagePlaceholder>
    )
  }

  if (imgSrc === 'background') {
    return <ProductCardImagePlaceholder breakpoint={br} />
  }

  return <ProductCardImg src={imgSrc} alt={title} breakpoint={br} />
}

const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
`

const StyledIcon = styled(FeatherIcon)`
  filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.4));
`

const CONTAINER_HEIGHT = (props) => {
  if (PHONE_BREAKPOINTS.includes(props.breakpoint)) {
    return '224px'
  }
  if (TABLET_BREAKPOINT.includes(props.breakpoint)) {
    return '338px'
  }

  return '378px'
}

const ProductCardContainer = styled.div<{ breakpoint: Breakpoint }>`
  border: 1px solid #969bab;
  border-radius: 10px;
  width: auto;
  overflow: hidden;
  height: ${(props) => CONTAINER_HEIGHT(props)};
  background-color: white;
`

const ProductCardImg = styled.img<{ breakpoint: Breakpoint }>`
  object-fit: cover;
  height: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakpoint) ? '150px' : '70%'};
  width: 100%;
  border-bottom: 1px solid lightgray;
`

const ProductCardImagePlaceholder = styled.div<{ breakpoint: Breakpoint }>`
  height: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakpoint) ? '60%' : '70%'};
  width: 100%;
  background-color: #ececec;
`

const ProductDetailsContainer = styled.div<{ breakpoint: Breakpoint }>`
  display: flex;
  flex-direction: column;
  height: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakpoint) ? '40%' : '30%'};
`
const ProductDetailsInnerContainer = styled.div<{ breakpoint: Breakpoint }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    !PHONE_BREAKPOINTS.includes(props.breakpoint) &&
    'flex-grow: 1; margin: 0 8px'};
`

const ProductCardTitle = styled.span<{
  type: 'small' | 'large'
  isPlaceholder: boolean
}>`
  font-family: 'Gidole';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.type === 'small' ? '12px' : '16px')};
  line-height: ${(props) => (props.type === 'small' ? '16px' : '24px')};
  margin: ${(props) => (props.type === 'small' ? '8px' : '8px 16px')};
  height: ${(props) => (props.type === 'small' ? '32px' : '48px')};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPlaceholder ? 'transparent' : 'black')};
  background-color: ${(props) =>
    props.isPlaceholder ? '#F4F5F7' : 'transparent'};
`

const ProductCardPrice = styled.span<{
  type: 'small' | 'large'
  isPlaceholder: boolean
}>`
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 700;
  margin: 0 8px;
  font-size: ${(props) => (props.type === 'small' ? '12px' : '14px')};
  line-height: ${(props) => (props.type === 'small' ? '12px' : '14px')};
  margin-right: ${(props) => (props.isPlaceholder ? '8px' : '12px')};
  color: ${(props) => (props.isPlaceholder ? 'transparent' : '#00C6AE')};
  background: ${(props) => (props.isPlaceholder ? '#E9E7FC' : 'transparent')};
  width: ${(props) => (props.isPlaceholder ? '100%' : 'auto')};
`

const StyledPrice = styled.span`
  display: block;
  font-family: 'DIN 2014';
  font-weight: 800;
  font-size: 14px;
  color: ${colors.carribeanGreen};
  margin: 0 8px;
`

const ProductCardLink = styled.span<{ type: 'small' | 'large' }>`
  color: #f15b3b;
  font-size: ${(props) => (props.type === 'small' ? '12px' : '14px')};
`

export default ProductCard
