import React, { useReducer, useState } from 'react'
import { LOGOUT_ACCOUNT, reducer, UPDATE_USER } from './reducers'
import UserContext from './UserContext'

export default ({ children }) => {
  const [showLogInModal, setShowLogInModal] = useState(false)
  const [state, dispatch] = useReducer(reducer, {
    user: undefined,
  })

  const updateUser = (userDetails) => {
    dispatch({ type: UPDATE_USER, userDetails })
  }

  const logoutAccount = () => {
    dispatch({ type: LOGOUT_ACCOUNT })
  }

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        updateUser: updateUser as any,
        logoutAccount: logoutAccount,
        showLogInModal: showLogInModal,
        setShowLogInModal: setShowLogInModal,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
