import Button from 'components/buttons'
import { colors } from 'config/constants'
import useAuthentication from 'hooks/useAuthentication'
import useBreakpoint, {
  Breakpoint,
  PHONE_BREAKPOINTS,
} from 'hooks/useBreakPoint'
import React from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'

const SignupSection = () => {
  const br = useBreakpoint()
  const { setShowLogInModal } = useAuthentication()
  return (
    <StyledContainer
      fluid
      className="d-flex flex-column align-items-center"
      breakPoint={br}
    >
      <StyledLabel breakPoint={br} className="mb-4">
        GET THE FULL LOMA EXPERIENCE
      </StyledLabel>
      <Button text="Sign Up" onClick={() => setShowLogInModal(true)} />
    </StyledContainer>
  )
}

export default SignupSection

const StyledContainer = styled(Container)<{ breakPoint: Breakpoint }>`
  background-color: ${colors.skyBlue};
  border-top: 1.5px solid black;
  border-bottom: 1px solid black;
  padding: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint) ? '2em' : '7em'};
`

const StyledLabel = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 900;
  font-family: OZIK-Black;
  font-size: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint) ? '32px' : '64px'};
  text-align: center;
`
