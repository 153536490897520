import Typography from 'components/TypographyV2'
import FeatherIcon from 'feather-icons-react'
import useBreakpoint, { PHONE_BREAKPOINTS } from 'hooks/useBreakPoint'
import useNavigation from 'hooks/useNavigation'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import LomaLogoV2 from '../Logo'

const FooterV2 = () => {
  const br = useBreakpoint()
  const { twitter, facebook, instagram, mail } = useNavigation()

  return (
    <Container
      fluid
      style={{ backgroundColor: 'black' }}
      className="position-relative"
    >
      <Container className="text-white pt-5 pb-4">
        <Row className="d-flex align-items-center justify-content-md-center justify-content-lg-start">
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={2}
            className={PHONE_BREAKPOINTS.includes(br) ? 'p-0 mb-4' : 'p-0'}
          >
            <LomaLogoV2 />
          </Col>
          <Col xs={6} sm={6} md={4} lg={2} className="ml-lg-3">
            <Row>
              <NavLink to="/about">
                <Typography.TextLight>About us</Typography.TextLight>
              </NavLink>
            </Row>
            <Row>
              <NavLink to="/missing-sites">
                <Typography.TextLight>
                  Are we missing a site?
                </Typography.TextLight>
              </NavLink>
            </Row>
          </Col>
          <Col xs={6} sm={6} md={2} lg={2} className="offset-lg-1">
            <Row>
              <Typography.TextLight>Keep up with us:</Typography.TextLight>
            </Row>
            <Row>
              <Col className="p-0" onClick={instagram}>
                <FeatherIcon
                  icon="instagram"
                  size={25}
                  style={{ color: 'white', cursor: 'pointer' }}
                />
              </Col>
              <Col className="p-0" onClick={facebook}>
                <FeatherIcon
                  icon="facebook"
                  size={25}
                  style={{ color: 'white', cursor: 'pointer' }}
                />
              </Col>
              <Col className="p-0" onClick={twitter}>
                <FeatherIcon
                  icon="twitter"
                  size={25}
                  style={{ color: 'white', cursor: 'pointer' }}
                />
              </Col>
              <Col className="p-0">
                <FeatherIcon
                  onClick={mail}
                  icon="mail"
                  size={25}
                  style={{ color: 'white', cursor: 'pointer' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4 pl-lg-0 pl-md-5">
          <Typography.TextDarkGray
            style={{
              color: '#474A57',
              fontWeight: 400,
            }}
          >
            © 2022 Loma Technologies Ltd.
          </Typography.TextDarkGray>
        </Row>
      </Container>
    </Container>
  )
}

export default FooterV2
