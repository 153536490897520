import ProductCard from 'components/ProductCard'
import useBreakpoint, { PHONE_BREAKPOINTS } from 'hooks/useBreakPoint'
import React from 'react'

export default function PlaceholderProductCard() {
  const br = useBreakpoint()
  return (
    <ProductCard
      type={PHONE_BREAKPOINTS.includes(br) ? 'small' : 'large'}
      imgSrc="background"
      title="placeholder"
      price={0}
      productUrl=""
      siteName=""
      hideLike={true}
      placeholder={true}
    />
  )
}
