import CloseX from 'assets/img/close.svg'
import Button from 'components/buttons'
import Input from 'components/input'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

export default function LogIn({
  onClose,
  loginWithGoogle,
  loginWithApple,
  loginWithEmail,
  signUpWithEmail,
  error = undefined,
}) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState<'signin' | 'signup'>('signin')
  const [email, setEmail] = useState('')

  const [busy, setBusy] = useState(false)

  const [stateError, setStateError] = useState(error)

  useEffect(() => {
    setStateError(error)

    if (error) {
      setBusy(false)
    }
  }, [error])

  const onUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStateError(undefined)
      setUsername(event.currentTarget.value)
    },
    []
  )

  const onPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStateError(undefined)
      setPassword(event.currentTarget.value)
    },
    []
  )

  const onEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStateError(undefined)
      setEmail(event.currentTarget.value)
    },
    []
  )

  const onLoginWithEmail = useCallback(
    (event?) => {
      event?.preventDefault()
      loginWithEmail(username, password)
    },
    [loginWithEmail, password, username]
  )

  const onSignUp = useCallback(() => {
    signUpWithEmail(username, password, email)
  }, [email, password, signUpWithEmail, username])

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setBusy(true)
      if (type === 'signup') {
        onSignUp()
      } else {
        onLoginWithEmail()
      }
    },
    [type, onSignUp, onLoginWithEmail]
  )

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        if (username !== '' && password !== '') {
          onSubmit(event)
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [onSubmit, password, username])

  const submitButtonText = useMemo(() => {
    if (busy) {
      return 'Please wait...'
    }

    if (type === 'signup') {
      return 'Sign Up'
    }
    return 'Log in'
  }, [busy, type])

  const handleOnClose = useCallback(() => {
    setStateError(undefined)
    onClose()
  }, [onClose])

  return (
    <Container>
      <ColouredContainer>
        <Title>{type === 'signup' ? 'SIGN UP' : 'LOG IN'}</Title>
        <Row>
          <Button
            social="google"
            text={
              type === 'signup' ? 'Sign up with Google' : 'Log in with Google'
            }
            style={{ width: '100%', height: '100%' }}
            onClick={loginWithGoogle}
          />
        </Row>
        <Row>
          <Button
            social="apple"
            text={
              type === 'signup' ? 'Sign up with Apple' : 'Log in with Apple'
            }
            style={{ width: '100%', height: '100%' }}
            onClick={loginWithApple}
          />
        </Row>
        <HR />
        <Message>
          {type === 'signup' ? 'Sign up with email' : 'Log in with email'}
        </Message>
        <form onSubmit={onSubmit}>
          <Row>
            <Input
              icon={type === 'signup' ? 'mail' : 'user'}
              placeholder={type === 'signup' ? 'Email' : 'Username'}
              onChange={type === 'signup' ? onEmailChange : onUsernameChange}
              error={stateError}
            />
          </Row>
          <Row>
            <Input
              icon="lock"
              placeholder="Password"
              type="password"
              onChange={onPasswordChange}
              error={stateError}
            />
          </Row>
          {type === 'signup' && (
            <Row>
              <Input
                icon="user"
                placeholder="Username"
                onChange={onUsernameChange}
              />
            </Row>
          )}
          <Row>
            <Button
              type="tertiary"
              text={submitButtonText}
              style={{ width: '100%', height: '100%' }}
              onClick={onSubmit}
            />
          </Row>
        </form>
        <Message>
          {type === 'signin' ? 'You new here?' : 'Already have an account?'}
          &nbsp;
          <span
            role="button"
            tabIndex={0}
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              setType(type === 'signin' ? 'signup' : 'signin')
            }}
          >
            {type === 'signin' ? 'Sign up' : 'Log in'}
          </span>
        </Message>
      </ColouredContainer>
      <CloseContainer onClick={handleOnClose}>
        <img src={CloseX} alt="close" />
      </CloseContainer>
    </Container>
  )
}

const HR = styled.hr`
  margin-bottom: 16px;
  width: 243px;
`

const Row = styled.div`
  width: 265px;
  min-height: 46px;
  margin-bottom: 16px;
`

const Container = styled.div`
  position: relative;
  overflow: visible;
  padding-top: 18px;
  padding-right: 18px;
  margin-left: 18px;
  width: 297px;
  height: 477px;
`

const CloseContainer = styled.button`
  position: absolute;
  top: -10px;
  right: -20px;
  width: 48px;
  height: 48px;
  background: #f15b3b;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const ColouredContainer = styled.div`
  display: flex;
  position: relative;
  width: 297px;
  min-height: 477px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`

const Title = styled.span`
  font-family: OZIK-Black;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  /* or 111% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
  margin-bottom: 21px;
`

const Message = styled.span`
  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 16px;
`
