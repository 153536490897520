import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

interface AboutProps extends RouteComponentProps {
  minHeight?: number
  iframeSrc: string
}

function ExternalPage(props?: AboutProps) {
  const { minHeight = 1024, iframeSrc } = props
  return (
    <div style={{ width: '100%', minHeight: minHeight, display: 'flex' }}>
      <iframe
        title="about"
        src={iframeSrc}
        style={{ width: '100%', height: minHeight, flexGrow: 1 }}
        frameBorder="0"
      />
    </div>
  )
}

export default ExternalPage
