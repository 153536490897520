export const colors = {
  primaryCream: '#F5F4F0',
  primaryBlue: '#1296B4',
  primaryRed: '#EB5131',
  primaryGreen: '#01A688',
  primaryOrange: '#E7941E',
  darkPrimary: '#3A3433',
  dark200: '#EBE4DF',
  dark300: '#C3B9B5',
  dark400: '#887F7C',
  dark500: '#3A3433',
  dark: '#887F7C',
  lightGreen: '#F2FFF8',
  lightCream: '#FEFFFA',
  errorColor: '#EB5131',
  gray: '#C4C4C4',
  dividerGray: '#EEEFF4',
  textBlack: '#18191F',
  textDarkGray: '##474A57',
  black: '#000000',
  white: '#FFFFFF',
  skyBlue: '#E7F8FC',
  turquoiseBlue: '#61E4C5',
  selago: '#E9E7FC',
  flamingo: '#F15B3B',
  persianBlue: '#1947E5',
  carribeanGreen: '#00C6AE',
  foam: '#D6FCF7',
  merino: '#FAF7F2',
}

export const popular = [
  'slip dress',
  'patagonia',
  'apple watch',
  'playstation 5',
  'bottega venetta boots',
  'mid century modern',
  'dining table',
]

export const searchSuggestions = [
  'pants',
  'party',
  'panel',
  'baquet',
  'basket',
  'bag',
  'purse',
  'phone',
  'jacket',
  'jagger',
  'jaggies',
  'jaybird',
  'slip dress',
  'stussy hoodie',
  'apple watch',
  'playstation 5',
  'bottega venetta boots',
  'midcentury modern',
  'floor lamp',
  'barbour jacket',
]

export const sites = {
  ebay: 'ebay',
  gumtree: 'gumtree',
  depop: 'depop',
  etsy: 'etsy',
  freecycle: 'freecycle',
}

export const categories = [
  { value: '', label: 'All Categories' },
  { value: 'clothes', label: 'Clothes' },
  { value: 'homeware', label: 'Homeware' },
  { value: 'electronics', label: 'Electronics' },
]

export const sortOptions = {
  // trending: 'trending',
  // recent: 'recent',
  highest: 'highest',
  lowest: 'lowest',
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '575px',
  tablet: '768px',
  laptopS: '992px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptopS: `(min-width: ${size.laptopS})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
}
