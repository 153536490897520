import CloseX from 'assets/img/close.svg'
import Categories from 'components/categories'
import { PriceFilter, SiteFilter } from 'components/Filters'
import React from 'react'
import styled from 'styled-components'
import LogInModal from './Login'
import TitleAndAlert from './TitleAndAlert'

interface Props {
  title: string
  message: string
}

export function LogIn({
  onClose,
  loginWithGoogle,
  loginWithApple,
  loginWithEmail,
  signUpWithEmail,
  error,
}) {
  return (
    <LogInModal
      onClose={onClose}
      loginWithGoogle={loginWithGoogle}
      loginWithApple={loginWithApple}
      loginWithEmail={loginWithEmail}
      signUpWithEmail={signUpWithEmail}
      error={error}
    />
  )
}

export function Alert({ title, message }: Props) {
  return <TitleAndAlert title={title} message={message} />
}

export function FilterModal({
  categories,
  busy,
  categoryCounts,
  setSelectedCategories,
  priceRangeFilter,
  setPriceRangeFilter,
  sites,
  filterSites,
  onClose,
}) {
  return (
    <div
      style={{
        padding: '12px',
        maxWidth: '667px',
        margin: '0 auto',
      }}
    >
      <Background>
        <FilterTitle>Filter Settings</FilterTitle>
        <div style={{ marginBottom: '16px', width: '100%' }}>
          <Categories
            categories={categories}
            isLoading={busy}
            counts={categoryCounts}
            onCategoriesSelectedChange={setSelectedCategories}
          />
        </div>
        <FiltersLabel style={{ width: '100%' }}>Price</FiltersLabel>
        <div style={{ marginBottom: '16px', width: '100%' }}>
          <PriceFilter
            priceRange={priceRangeFilter}
            onChangePriceRange={setPriceRangeFilter}
          />
        </div>
        <FiltersLabel style={{ width: '100%' }}>Sites</FiltersLabel>
        <div style={{ width: '100%' }}>
          <SiteFilter siteOptions={sites} onCheckboxToggle={filterSites} />
        </div>
        <CloseContainer onClick={onClose}>
          <img src={CloseX} alt="close" />
        </CloseContainer>
      </Background>
    </div>
  )
}

const CloseContainer = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 48px;
  height: 48px;
  background: #f15b3b;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const Background = styled.div`
  position: relative;
  min-width: 294px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`

const FiltersLabel = styled.span`
  font-family: 'Gidole';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  /* or 78% */

  display: flex;
  align-items: left;
  letter-spacing: -0.01em;

  color: #000000;
  margin-bottom: 12px;
`

const FilterTitle = styled.span`
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  /* or 167% */

  display: flex;
  align-items: center;
  letter-spacing: -1px;
`
