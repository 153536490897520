export const UPDATE_USER = 'UPDATE_USER'
export const LOGOUT_ACCOUNT = 'LOGOUT_ACCOUNT'

const updateUser = (userDetails, state) => ({ ...state, user: userDetails })
const logoutAccount = () => ({ user: undefined })

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return updateUser(action.userDetails, state)
    case LOGOUT_ACCOUNT:
      return logoutAccount()
    default:
      return state
  }
}
