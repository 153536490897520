import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'
import useBreakpoint, {
  Breakpoint,
  SMALLER_BREAKPOINTS,
} from 'hooks/useBreakPoint'

export type SortTypes = 'Lowest Price' | 'Highest Price'
const sortOptions: SortTypes[] = ['Lowest Price', 'Highest Price']

interface SortBoxProps {
  selectedSortValue: SortTypes
  changeSelectedSortValue?: (sortValue: SortTypes) => void
}

const SortBox = ({
  selectedSortValue,
  changeSelectedSortValue,
}: SortBoxProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const br = useBreakpoint()

  return (
    <SortBoxContainer breakPoint={br} isCollapsed={isCollapsed}>
      <IconContainer>
        <IndividualIconContainer onClick={() => setIsCollapsed(true)}>
          <FeatherIcon icon="chevron-up" size={12} />
        </IndividualIconContainer>
        <IndividualIconContainer onClick={() => setIsCollapsed(false)}>
          <FeatherIcon icon="chevron-down" size={12} />
        </IndividualIconContainer>
      </IconContainer>
      <OptionsContainer>
        <SelectedOptionSpan>{selectedSortValue}</SelectedOptionSpan>
        {!isCollapsed && (
          <>
            {sortOptions
              .filter((option) => option !== selectedSortValue)
              .map((option, index) => (
                <HiddenOptionsSpan
                  key={index}
                  onClick={() => {
                    if (changeSelectedSortValue) {
                      changeSelectedSortValue(option)
                      setIsCollapsed(true)
                    }
                  }}
                >
                  {option}
                </HiddenOptionsSpan>
              ))}
          </>
        )}
      </OptionsContainer>
    </SortBoxContainer>
  )
}

const SortBoxContainer = styled.div<{
  isCollapsed: boolean
  breakPoint: Breakpoint
}>`
  border: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint)
      ? '1.5px solid black'
      : '1.5px solid #969bab'};
  border-radius: 8px;
  padding: 6px 4px;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  align-items: ${(props) => (props.isCollapsed ? 'center' : 'unset')};
  ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) &&
    'box-shadow: 0px 1px 0px #18191F'};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IndividualIconContainer = styled.div`
  display: flex;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SelectedOptionSpan = styled.span`
  font-size: 12px;
  margin-left: 12px;
`

const HiddenOptionsSpan = styled.span`
  font-size: 12px;
  margin-left: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
`

export default SortBox
