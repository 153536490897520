import {
  addItemToLikesFirestore,
  removeItemFromLikesFirestore,
} from 'firestore/likes'
import { useCallback } from 'react'
import { ListingType } from './useCrawlers'

export default function useLikes() {
  const addLike = useCallback((item: ListingType) => {
    addItemToLikesFirestore(item)
  }, [])

  const removeLike = useCallback((item: ListingType) => {
    removeItemFromLikesFirestore(item)
  }, [])

  return { addLike, removeLike }
}
