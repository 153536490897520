import UserContext from 'context/UserContext'
import { auth, getUserDetails, loginAccount, registerAccount } from 'firestore'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

export default function useAuthentication() {
  const context = useContext(UserContext)
  const [error, setError] = useState(undefined)
  const { updateUser, user, showLogInModal, setShowLogInModal } = context

  // The name logoutAccount is very misleading as it doesn't actually log you out from firebase
  const actuallySignOut = useCallback(() => {
    auth.signOut().then(() => {
      updateUser(undefined)
    })
  }, [updateUser])

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged((x) => {
      if (x && user !== null && !user) {
        getUserDetails(x.uid)
          .then((userDetails) => updateUser(userDetails))
          .catch((err) => {
            console.error(err)
            updateUser(null)
          })
      }
    })
    return subscriber
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!user && showLogInModal) {
      setShowLogInModal(false)
    }
  }, [setShowLogInModal, showLogInModal, user])

  const unauthenticated = useMemo(() => !user, [user])

  const loginWithEmail = useCallback((username: string, password: string) => {
    loginAccount({
      username: username.trim(),
      password: password.trim(),
    }).catch((e) => {
      setError(`${e.toString()} MS: ${new Date().getMilliseconds()}`)
    })
  }, [])

  const signUpWithEmail = useCallback(
    (username: string, password: string, email: string) => {
      registerAccount({
        email: email,
        username: username,
        password,
        name: '',
      }).catch((e) => {
        setError(`${e.toString()} MS: ${new Date().getMilliseconds()}`)
      })
    },
    []
  )

  return {
    updateUser,
    user,
    logoutAccount: actuallySignOut,
    showLogInModal,
    setShowLogInModal,
    unauthenticated,
    loginWithEmail,
    error,
    signUpWithEmail,
  }
}
