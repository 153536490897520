import SearchBox from 'components/SearchBox'
import { colors } from 'config/constants'
import useNavigation from 'hooks/useNavigation'
import { useTryKeywords } from 'hooks/useTryKeywords'
import React, { useCallback } from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'

const SearchSection = () => {
  const { results } = useNavigation()

  const onSearch = useCallback(
    (query) => {
      results(query)
    },
    [results]
  )

  return (
    <StyledContainer
      fluid
      className="px-5 py-5 d-flex flex-column justify-content-center h-100"
    >
      <StyledHeadline className="mb-3">
        SEARCH EVERY SECONDHAND AND RENTAL SITE
      </StyledHeadline>
      <SearchBox type="light" onSearch={onSearch} showGradient={true} />
      <TryKeywords navigateToResults={results} />
    </StyledContainer>
  )
}

function TryKeywords({ navigateToResults }) {
  const { keywords } = useTryKeywords()
  return (
    <div className="d-flex align-items-center mt-3 flex-wrap">
      <span className="mr-3">Try: </span>
      {keywords.map((keyword) => (
        <ChipsContainer
          className="m-2"
          onClick={() => navigateToResults(keyword)}
        >
          <ChipsText>{keyword}</ChipsText>
        </ChipsContainer>
      ))}
    </div>
  )
}

const StyledContainer = styled(Container)`
  background-color: ${colors.merino};
  border-bottom: 0px;
  border-top: 0px solid black;
  border-right: 2.5px solid black;
  @media only screen and (max-width: 980px) {
    border-bottom: 1px solid black;
    border-right: 0px solid black;
    border-top: 2px solid black;
  } ;
`

const StyledHeadline = styled.span`
  font-family: 'Ozik-Black';
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 48px;
  /* or 80% */

  display: flex;
  align-items: center;
  max-width: 504px;
  @media only screen and (max-width: 980px) {
    font-size: 46px;
    line-height: 36px;
  } ;
`

const ChipsContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #c3b9b5;
  padding: 8px 16px 8px 16px;
  background-color: white;
  color: #c3b9b5;
  cursor: pointer;
`

const ChipsText = styled.span`
  font-family: 'Gidole';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #887f7c;
`

export default SearchSection
