/* eslint-disable */

import ExpandedProductCard from 'components/ExpandedProductCard'
import ProductCard from 'components/ProductCard'
import { chunk } from 'config/helper'
import useBreakpoint, {
  Breakpoint,
  PHONE_BREAKPOINTS,
  SMALLER_BREAKPOINTS,
  TABLET_BREAKPOINT,
} from 'hooks/useBreakPoint'
import useFeaturedListings from 'hooks/useFeaturedListings'
import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'
import styled from 'styled-components'

const RecentFindsSection = () => {
  const br = useBreakpoint()
  const [selectedData, setSelectedData] = useState(null)
  const { featuredListings } = useFeaturedListings(br)
  let counter = 0

  const NUMBER_OF_COLUMNS = PHONE_BREAKPOINTS.includes(br)
    ? 2
    : TABLET_BREAKPOINT.includes(br)
    ? 3
    : 5

  const handleProductCardClick = (data: any, index: number) => () => {
    let rowNumber
    const chunkedArray = chunk(
      featuredListings.map((data, index) => index),
      NUMBER_OF_COLUMNS
    )

    chunkedArray.forEach((element, arrIndex) => {
      if (element.includes(index)) {
        rowNumber = arrIndex
        return
      }
    })
    document.querySelectorAll('.expanded-content').forEach((element) => {
      element.className = 'd-none expanded-content'
    })

    document.getElementById(`row${rowNumber}`)?.classList.toggle('d-none')

    setSelectedData(data)
  }

  return (
    <StyledContainer fluid breakPoint={br}>
      <StyledHeadline breakPoint={br}>Recent finds: </StyledHeadline>
      <Row className="d-flex row-wrap">
        {featuredListings.map((data, index) => (
          <>
            <CardContainer
              breakPoint={br}
              key={index}
              onClick={handleProductCardClick(data, index)}
            >
              <ProductCard
                imgSrc={data.images}
                price={data.price}
                productUrl={data.url}
                title={data.title}
                type={PHONE_BREAKPOINTS.includes(br) ? 'small' : 'large'}
                siteName={data.siteName}
                hideLike={true}
              />
            </CardContainer>
            {/* show the expanded card state after every element that is divisible by the number of columns */}
            {(index + 1) % NUMBER_OF_COLUMNS === 0 && (
              <div
                id={`row${counter++}`}
                className="d-none expanded-content"
                style={{
                  flexBasis: '100%',
                  margin: '12px',
                  border: '1px solid #F5F4F0',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 15.9018px rgba(58, 52, 51, 0.08)',
                }}
              >
                <ExpandedProductCard data={selectedData} />
              </div>
            )}
          </>
        ))}
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)<{ breakPoint: Breakpoint }>`
  border-top: 1.5px solid black;
  border-bottom: 1px solid black;
  padding: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '3em 1em' : '3em 5em'};
`

const CardContainer = styled.div<{ breakPoint: Breakpoint }>`
  flex-basis: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '50%'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '33%'
      : '20%'};
  padding: 12px;
`
const StyledHeadline = styled.span<{ breakPoint: Breakpoint }>`
  font-family: 'DIN 2014';
  font-weight: 800;
  font-style: italic;
  font-size: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '24px' : '36px'};
`

export default RecentFindsSection
