import React from 'react'

interface LogoProps {
  width?: number
  height?: number
}

const Logo = ({ width, height }: LogoProps) => (
  <svg
    width={width ? width.toString() : '135'}
    height={height ? height.toString() : '38'}
    viewBox="0 0 308 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.105423 43.9723L0.133186 86.3509L25.1016 86.3787L50.0608 86.3972V73.0353V59.6735H39.5755H29.081L29.0255 52.7199C28.9885 48.9009 28.9607 36.3436 28.9607 24.8126C28.9607 13.2817 28.9329 3.34128 28.8959 2.73098L28.8404 1.60285H14.459H0.0869141L0.105423 43.9723Z"
      fill="white"
    />
    <path
      d="M86.3122 0.1679C86.1364 0.186394 85.4701 0.251122 84.8315 0.306602C84.193 0.362083 82.9529 0.528526 82.0645 0.676483C71.2275 2.47963 62.26 8.41616 56.7258 17.4504C54.9768 20.2985 53.7274 23.054 52.5984 26.5124C50.0812 34.2428 49.3871 44.1833 50.7382 53.3285C53.5701 72.4881 65.462 85.1009 82.9344 87.4866C85.1369 87.7918 86.5529 87.875 89.1348 87.875C92.8644 87.875 95.6685 87.5883 98.8705 86.8486C104.275 85.6187 109.198 83.2793 113.381 79.9504C114.686 78.9055 117.601 75.9927 118.638 74.6981C124.311 67.5687 127.43 58.6732 128.151 47.6139C128.272 45.709 128.216 39.0605 128.059 37.5625C127.698 34.1596 127.346 32.0236 126.662 29.2772C124.811 21.8242 121.488 15.7397 116.574 10.8389C110.309 4.56944 102.137 0.972382 92.4757 0.204887C91.4022 0.121666 87.1081 0.0939255 86.3122 0.1679ZM91.1523 26.9747C96.6679 27.9364 100.333 32.4027 101.443 39.5043C101.647 40.7989 101.739 44.923 101.591 46.5135C101.166 51.2202 99.7589 54.8727 97.4083 57.3879C95.0114 59.9493 91.3652 61.2254 87.7282 60.7723C81.9904 60.051 78.1776 55.446 77.1966 48.0577C76.9838 46.5042 76.919 43.3695 77.0486 41.6589C77.4372 36.5731 78.8902 32.7448 81.3889 30.1557C81.8238 29.6933 82.4624 29.1108 82.7956 28.8611C83.9801 27.9642 85.6274 27.2521 87.1174 26.984C88.0891 26.8083 90.1621 26.799 91.1523 26.9747Z"
      fill="white"
    />
    <path
      d="M128.217 44V86.4434H141.589H154.962V57.1214V27.7994L158.09 27.8456C160.829 27.8826 161.32 27.9104 162.106 28.086C164.235 28.5484 165.512 29.2049 166.761 30.4533C168.196 31.8865 168.992 33.6434 169.464 36.3435C169.612 37.2405 169.63 38.8125 169.649 61.8743L169.677 86.4434L182.707 86.4249L195.728 86.3972L195.756 57.0936L195.774 27.7994L198.995 27.8456C201.799 27.8826 202.317 27.9104 203.085 28.086C207.287 29.0015 209.498 31.4519 210.368 36.1401C210.526 36.9723 210.535 38.3871 210.498 61.7264L210.47 86.4434H223.991H237.511V60.1544C237.511 43.4914 237.474 33.3845 237.419 32.5338C236.864 24.5445 234.43 17.8589 230.219 12.7546C229.904 12.3663 229.127 11.534 228.507 10.9053C223.537 5.88417 216.763 2.84193 208.406 1.88026C206.157 1.62134 203.881 1.55661 196.727 1.55661H189.574V8.90791C189.574 16.0743 189.574 16.2592 189.398 16.2592C189.287 16.2592 189.129 16.102 188.963 15.8154C188.417 14.8722 187.399 13.4574 186.575 12.505C181.606 6.70715 173.823 3.31353 162.643 2.07444C158.608 1.62134 155.999 1.56586 141.293 1.55661H128.217V44Z"
      fill="white"
    />
    <path
      d="M258.491 1.41155C258.491 1.46703 261.388 5.39697 264.932 10.1591C268.467 14.9306 271.355 18.8328 271.336 18.8512C271.318 18.8697 270.642 18.9252 269.828 18.99C265.848 19.2674 262.572 19.8684 259.287 20.9318C248.006 24.5843 240.574 32.9436 238.27 44.5577C237.289 49.4956 237.261 55.4598 238.178 60.7861C239.353 67.5641 242.194 73.2047 246.516 77.3288C251.708 82.2944 258.972 85.2812 268.347 86.3076C271.753 86.6774 273.187 86.7052 284.311 86.6497C294.5 86.5942 307.558 86.4278 307.799 86.3538C307.882 86.3168 307.91 80.0659 307.91 55.4876V24.6583L300.543 13.1551C296.49 6.83025 293.112 1.578 293.029 1.48553C292.899 1.33758 291.955 1.32833 275.686 1.32833C264.978 1.32833 258.491 1.36532 258.491 1.41155ZM277.472 39.7031C280.85 40.3873 283.645 42.5511 285.236 45.7136C285.764 46.7585 286.088 47.7201 286.365 48.987C286.643 50.2631 286.662 53.1204 286.412 54.3132C285.699 57.6144 283.941 60.296 281.424 61.9327C279.378 63.255 277.583 63.7821 275.103 63.7821C272.586 63.7821 270.568 63.1718 268.486 61.7755C267.597 61.1837 266.144 59.7319 265.552 58.8442C264.071 56.6342 263.451 54.5351 263.451 51.7703C263.451 49.5418 263.794 47.9328 264.645 46.1112C266.922 41.2381 272.151 38.6304 277.472 39.7031Z"
      fill="white"
    />
  </svg>
)

export default Logo
