import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import useBreakpoint, {
  Breakpoint,
  SMALLER_BREAKPOINTS,
} from 'hooks/useBreakPoint'

interface StatusMarqueeProps {
  isSearching: boolean
  text?: string
}

const StatusMarquee = (props: StatusMarqueeProps) => {
  const br = useBreakpoint()

  const { isSearching, text } = props

  const [counter, setCounter] = useState(1)

  //  dots animation
  useEffect(() => {
    if (isSearching) {
      setTimeout(() => {
        if (counter === 3) {
          setCounter(1)
          return
        }

        setCounter(counter + 1)
      }, 300)
    }
  }, [counter, isSearching])

  return (
    <StatusMarqueeContainer isSearching={isSearching} breakpoint={br}>
      {isSearching ? (
        <SearchingSpan>
          {`Searching ${Array.from(Array(counter)).map(() => '.')}`.replaceAll(
            ',',
            ''
          )}
        </SearchingSpan>
      ) : (
        <FoundSpan>{text}</FoundSpan>
      )}
    </StatusMarqueeContainer>
  )
}

const StatusMarqueeContainer = styled.div<{
  isSearching: boolean
  breakpoint: Breakpoint
}>`
  background-color: ${(props) => (props.isSearching ? '#FFF4CC' : '#E9E7FC')};
  width: 102%;
  padding: 0 16px;
  height: auto;
  display: flex;
  align-items: center;
  position: fixed;
  left: -2px;
  top: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakpoint) ? '70px' : '76px'};
  z-index: 2;
`

const styledSpan = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  line-height: 16px;
  padding: 12px 0px 12px 0px;
`

const SearchingSpan = styled(styledSpan)`
  color: #ffbd12;
`

const FoundSpan = styled(styledSpan)`
  color: #1947e5;
`

export default StatusMarquee
