import React from 'react'
import styled from 'styled-components'

import { colors } from 'config/constants'

const Divider = styled.div`
  border-top: 1px solid ${colors.dividerGray};
  height: 1px;
  width: 100%;
`

interface VerticalDividerProps {
  backgroundColor?: string
  width?: string
}

export const VerticalDivider = styled.div<VerticalDividerProps>`
  width: ${(props) => props.width || '1px'};
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.dividerGray};
`

export default Divider
