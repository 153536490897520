import React from 'react'
import styled from 'styled-components'

import CheckBox from '../../Checkbox'

export type SiteType = {
  name: string
  isActive: boolean
  numberOfResults: number
}

interface SiteFilterProps {
  siteOptions: SiteType[]
  onCheckboxToggle?: (siteObject: SiteType, isActive: boolean) => void
}

const SiteFilter = (props: SiteFilterProps) => {
  const { siteOptions, onCheckboxToggle } = props
  return (
    <SiteFilterContainer>
      {siteOptions.map((site, index) => (
        <SiteRows
          key={index}
          isActive={site.isActive}
          isLastElement={index === siteOptions.length - 1}
        >
          <div style={{ flexGrow: 1 }}>
            <CheckBox
              checked={site.isActive}
              type="success"
              label={site.name}
              onToggle={(toggled) => onCheckboxToggle(site, toggled)}
            />
          </div>
          <SiteNumberOfResults>
            {site.numberOfResults > 999 ? '999+' : site.numberOfResults}
          </SiteNumberOfResults>
        </SiteRows>
      ))}
    </SiteFilterContainer>
  )
}

const SiteFilterContainer = styled.div`
  border: 1.5px solid #969bab;
  border-radius: 12px;
  font-family: Gidole;
  overflow: hidden;
`

const SiteRows = styled.div<{ isActive: boolean; isLastElement: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px;
  border-bottom: ${(props) =>
    props.isLastElement ? '0' : '1.5px solid #969bab'};
  background-color: ${(props) => (props.isActive ? '#D6FCF7' : '#FFF')};
`

const SiteNumberOfResults = styled.span`
  margin-right: 8px;
  color: rgb(0, 198, 174);
  font-weight: bold;
  font-size: 14px;
`

export default SiteFilter
