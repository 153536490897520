/* eslint-disable */

import ExpandedProductCard from 'components/ExpandedProductCard'
import { PriceRange } from 'components/Filters/components/PriceFilter'
import { SiteType } from 'components/Filters/components/SiteFilter'
import { FilterModal } from 'components/modals'
import Overlay from 'components/Overlay'
import PlaceholderProductCard from 'components/PlaceholderProductCard'
import ProductCard from 'components/ProductCard'
import StatusMarquee from 'components/StatusMarquee'
import { chunk } from 'config/helper'
import useAuthentication from 'hooks/useAuthentication'
import useBreakpoint, {
  PHONE_BREAKPOINTS,
  SMALLER_BREAKPOINTS,
  TABLET_BREAKPOINT,
} from 'hooks/useBreakPoint'
import { ListingType, useCrawlers } from 'hooks/useCrawlers'
import useLikes from 'hooks/useLikes'
import useNavigation from 'hooks/useNavigation'
import useScrolling from 'hooks/useScrolling'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import SearchBox from '../../components/SearchBox'
import FilterBar from './components/FilterBar'
import SideBarContent from './components/SidebarContent'

interface ResultsPageV2Props {}

const ResultsPage = (props: ResultsPageV2Props) => {
  const br = useBreakpoint()
  const { results, query } = useNavigation()
  const { unauthenticated } = useAuthentication()
  const { addLike, removeLike } = useLikes()

  const NUMBER_OF_COLUMNS = PHONE_BREAKPOINTS.includes(br)
    ? 2
    : TABLET_BREAKPOINT.includes(br)
    ? 3
    : 4
  let counter = 0

  const [searchKey, setSearchKey] = useState(query)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [filteredSites, setFilteredSites] = useState<SiteType[]>([])
  const [priceRangeFilter, setPriceRangeFilter] = useState<PriceRange>({
    min: 0,
    max: 0,
  })
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [selectedListing, setSelectedListing] = useState(null)

  const toggleLike = useCallback((toggle: boolean, product: ListingType) => {
    if (toggle) {
      addLike(product)
    } else {
      removeLike(product)
    }
  }, [])

  const filterSites = useCallback(
    (siteObject: SiteType, isActive: boolean) => {
      const toBeFilteredSites: SiteType[] = sites
        .map((site) => {
          if (siteObject.name === site.name) {
            return {
              ...site,
              isActive,
            }
          }
          return site
        })
        .filter((site) => !site.isActive)

      if (filteredSites.length === 0) {
        setFilteredSites(toBeFilteredSites)
      } else if (!filteredSites.find((site) => site.name === siteObject.name)) {
        setFilteredSites([...filteredSites, ...toBeFilteredSites])
      } else {
        setFilteredSites(
          filteredSites
            .map((site) => {
              if (siteObject.name === site.name) {
                return {
                  ...site,
                  isActive,
                }
              }
              return site
            })
            .filter((site) => !site.isActive)
        )
      }
    },
    [filteredSites, setFilteredSites]
  )

  useEffect(() => {
    setSearchKey(query)
  }, [query])

  const { listings, crawling, classifying, categories, categoryCounts, sites } =
    useCrawlers(
      searchKey.toLocaleLowerCase(),
      selectedCategories,
      filteredSites,
      priceRangeFilter
    )

  const handleProductCardClick = (data: any, index: number) => () => {
    let rowNumber
    const chunkedArray = chunk(
      listings.map((listing, listingIndex) => listingIndex),
      NUMBER_OF_COLUMNS
    )

    chunkedArray.forEach((element, arrIndex) => {
      if (element.includes(index)) {
        rowNumber = arrIndex
      }
    })
    document.querySelectorAll('.expanded-content').forEach((element) => {
      element.className = 'd-none expanded-content'
    })

    document.getElementById(`row${rowNumber}`)?.classList.toggle('d-none')

    setSelectedListing(data)
  }

  return (
    <Container
      fluid
      style={{
        paddingBottom: '78px',
        paddingTop: PHONE_BREAKPOINTS.includes(br) ? '136px' : '116px',
        backgroundColor: '#FFFBF9',
      }}
    >
      <Overlay isHidden={!showMobileFilter} />
      <Container fluid className="px-0">
        <StatusMarquee
          isSearching={crawling}
          text={`Found ${listings.length?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} results for "${searchKey}". Use the search filters to refine your results.`}
        />
      </Container>

      {showMobileFilter && (
        <ModalContainer>
          <FilterModal
            categories={categories}
            busy={crawling || classifying}
            categoryCounts={categoryCounts}
            setSelectedCategories={setSelectedCategories}
            priceRangeFilter={priceRangeFilter}
            setPriceRangeFilter={setPriceRangeFilter}
            sites={sites}
            filterSites={filterSites}
            onClose={() => {
              setShowMobileFilter(false)
            }}
          />
        </ModalContainer>
      )}

      {/* Mobile Phones filter and search */}
      <Container className="py-1 px-2 d-sm-none">
        <Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <SearchBox
              type="light"
              value={query}
              onSearch={(searchValue) => {
                setSearchKey(searchValue)
                results(searchValue)
              }}
            />
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            style={{ paddingLeft: 0 }}
          >
            <FilterBar onFilterBarClicked={() => setShowMobileFilter(true)} />
          </Col>
        </Row>
      </Container>

      {/* Tablet View for filter and search */}
      <Container fluid className="d-none d-md-block d-lg-none pt-3">
        <Row className="d-flex align-items-center">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <SearchBox
              type="light"
              value={query}
              onSearch={(searchValue) => {
                setSearchKey(searchValue)
                results(searchValue)
              }}
            />
          </Col>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            xxl={4}
            style={{ paddingLeft: 0 }}
          >
            <FilterBar onFilterBarClicked={() => setShowMobileFilter(true)} />
          </Col>
        </Row>
      </Container>

      {/* Desktop View */}
      <Container fluid>
        <Row className="d-flex flex-row">
          <Col
            className="px-1 py-2 position-fixed d-none d-lg-block min-vh-100"
            style={{ maxWidth: '270px' }}
          >
            <SideBarContent
              categories={categories}
              categoryCounts={categoryCounts}
              isLoading={crawling || classifying}
              priceRangeFilter={priceRangeFilter}
              setPriceRangeFilter={setPriceRangeFilter}
              setSelectedCategories={setSelectedCategories}
              sites={sites}
              filterSites={filterSites}
            />
          </Col>
          <Col
            className="col py-2 min-vh-100"
            style={
              SMALLER_BREAKPOINTS.includes(br)
                ? { marginLeft: '0px' }
                : { marginLeft: '270px' }
            }
          >
            {/* Main Content */}
            <Row>
              {crawling &&
                [...Array(16).keys()].map((_, index) => (
                  <Col key={index} lg={3} md={4} sm={4} xs={6} className="p-2">
                    <PlaceholderProductCard />
                  </Col>
                ))}
              {listings.map((listing, index) => (
                <>
                  <Col
                    key={index}
                    lg={3}
                    md={4}
                    sm={4}
                    xs={6}
                    className="p-2"
                    onClick={handleProductCardClick(listing, index)}
                  >
                    <ProductCard
                      imgSrc={listing.images[0]}
                      price={listing.price}
                      productUrl={listing.url}
                      title={listing.title}
                      type={PHONE_BREAKPOINTS.includes(br) ? 'small' : 'large'}
                      siteName={listing.site}
                      onLike={(toggle: boolean) => {
                        toggleLike(toggle, listing)
                      }}
                    />
                  </Col>
                  {/* show the expanded card state after every element that is divisible by the number of columns */}
                  {(index + 1) % NUMBER_OF_COLUMNS === 0 && (
                    <div
                      id={`row${counter++}`}
                      className="d-none expanded-content"
                      style={{
                        flexBasis: '100%',
                        margin: '12px',
                        border: '1px solid #F5F4F0',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 15.9018px rgba(58, 52, 51, 0.08)',
                      }}
                    >
                      <ExpandedProductCard data={selectedListing} />
                    </div>
                  )}
                </>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

const ModalContainer = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
`

export default ResultsPage
