import React from 'react'
import styled from 'styled-components'

import { colors } from 'config/constants'

const DefaultSpanStyle = styled.span`
  font-family: 'DIN 2014';
  color: ${colors.textBlack};
`
const Headline = styled(DefaultSpanStyle)`
  font-weight: 800;
  font-size: 24px;
`

const Title = styled(DefaultSpanStyle)`
  font-size: 18px;
  font-weight: 700;
`

const Text = styled(DefaultSpanStyle)`
  font-size: 14px;
  font-weight: 400;
`
const TextLight = styled(DefaultSpanStyle)`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.white};
`
const TextDarkGray = styled(DefaultSpanStyle)`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.textDarkGray};
`

type TypographyType = {
  Headline: typeof Headline
  Title: typeof Title
  Text: typeof Text
  TextLight: typeof TextLight
  TextDarkGray: typeof TextDarkGray
}

const Typography: TypographyType = {
  Headline,
  Title,
  Text,
  TextLight,
  TextDarkGray,
}

export default Typography
