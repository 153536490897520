/* eslint-disable */

import React from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'

import { colors } from 'config/constants'
import useBreakpoint, {
  Breakpoint,
  PHONE_BREAKPOINTS,
  TABLET_BREAKPOINT,
} from 'hooks/useBreakPoint'

const TestimonySection = () => {
  const br = useBreakpoint()

  return (
    <StyledContainer fluid className="d-flex justify-content-center">
      <StyledInnerContainer
        breakPoint={br}
        className="d-flex flex-column align-items-center justify-content-center border border-dark"
      >
        <StyledQuote breakPoint={br}>&ldquo;</StyledQuote>
        <StyledTestimonyLabel breakPoint={br}>
          This is literally my dream! Thank you for bringing it to life!
        </StyledTestimonyLabel>
        <StyledReference breakPoint={br}>-Chloe R.B.</StyledReference>
      </StyledInnerContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  border-top: 1.5px solid black;
  border-bottom: 1px solid black;
`

const StyledInnerContainer = styled.div<{ breakPoint: Breakpoint }>`
  background-color: ${colors.selago};
  width: 75%;
  max-width: 985px;
  margin: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '5em 1em'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '6em 3em'
      : '8em 3em'};
  padding: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '5em 0.5em'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '5em 2em'
      : '5em 5em'};
`

const StyledTestimonyLabel = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 700;
  font-family: OZIK-BOLD;
  padding: 12px 24px;
  line-height: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '40px'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '48px'
      : '64px'};
  font-size: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '40px'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '48px'
      : '64px'};
  margin-top: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '-24px'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '-32px'
      : '-64px'};
`

const StyledQuote = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 700;
  font-family: Helvetica Neue;
  font-size: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '48px'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '64px'
      : '96px'};
  color: ${colors.flamingo};
`

const StyledReference = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 700;
  font-family: OZIK-BOLD;
  font-size: ${(props) =>
    PHONE_BREAKPOINTS.includes(props.breakPoint)
      ? '24px'
      : TABLET_BREAKPOINT.includes(props.breakPoint)
      ? '32px'
      : '48px'};
  color: ${colors.persianBlue};
`

export default TestimonySection
