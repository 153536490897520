import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAuthentication from './useAuthentication'

const external = (url: string) => (window.location.href = url)

export default function useNavigation() {
  const history = useHistory()
  const location = useLocation()
  const { unauthenticated, setShowLogInModal } = useAuthentication()

  const query = useMemo(
    () => new URLSearchParams(location.search).get('query'),
    [location]
  )

  const home = useCallback(() => {
    history.push('/')
  }, [history])

  const results = useCallback(
    (q: string) => {
      history.push(`/results?query=${q}`, q)
    },
    [history]
  )

  const profile = useCallback(() => {
    if (unauthenticated) {
      setShowLogInModal(true)
    } else {
      history.push('/my-profile')
    }
  }, [history, setShowLogInModal, unauthenticated])

  const about = useCallback(() => {
    history.push('/about')
  }, [history])

  const missingSite = useCallback(() => {
    history.push('/missing-sites')
  }, [history])

  const twitter = useCallback(() => {
    external('https://twitter.com/lomasearch')
  }, [])

  const instagram = useCallback(() => {
    external('https://www.instagram.com/lomasearch/')
  }, [])

  const tiktok = useCallback(() => {
    external('https://www.tiktok.com/@lomasearch')
  }, [])

  const facebook = useCallback(() => {
    external('https://www.facebook.com/lomasearch')
  }, [])

  const mail = useCallback(() => {
    external('mailto:contact@asklomafirst.com')
  }, [])

  return {
    home,
    results,
    profile,
    query: query || '',
    about,
    missingSite,
    twitter,
    instagram,
    tiktok,
    facebook,
    mail,
  }
}
