import useBreakpoint, {
  Breakpoint,
  SMALLER_BREAKPOINTS,
} from 'hooks/useBreakPoint'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import BinocularsImg from '../../../assets/img/binoculars-gif400.gif'
import EarthFromAfarImg from '../../../assets/img/earth-gif400.gif'
import HugImg from '../../../assets/img/hearts-gif400.gif'

const SecondHandSearchSection = () => {
  const br = useBreakpoint()
  return (
    <StyledContainer fluid className="d-flex flex-column align-items-center">
      <StyledHeadline breakPoint={br}>
        START YOUR SECONDHAND SEARCH ON LOMA
      </StyledHeadline>
      <Row className="mt-5">
        <Col
          xs={12}
          sm={12}
          md={4}
          className="d-flex flex-column align-items-center"
        >
          <StyledImage breakPoint={br} src={BinocularsImg} alt="binoculars" />
          <Styledlabel breakPoint={br} className="mt-3">
            Compare across {'\n'}
            multiple sites
          </Styledlabel>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          className="d-flex flex-column align-items-center"
        >
          <StyledImage breakPoint={br} src={HugImg} alt="hug" />
          <Styledlabel breakPoint={br} className="mt-3">
            Bookmark your faves
          </Styledlabel>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          asd
          className="d-flex flex-column align-items-center"
        >
          <StyledImage breakPoint={br} src={EarthFromAfarImg} alt="earth" />
          <Styledlabel breakPoint={br} className="mt-3">
            Good for budgets, Better for the planet
          </Styledlabel>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  border-top: 1.5px solid black;
  border-bottom: 1px solid black;
  background-color: #faf7f2;
  padding-top: 100px;
  padding-bottom: 100px;
`

const StyledImage = styled.img<{ breakPoint: Breakpoint }>`
  border-radius: 50%;
  height: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '174.88px' : '339px'};
  width: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '174.88px' : '339px'};
  object-fit: cover;
`

const StyledHeadline = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 900;
  font-family: OZIK-Black;
  text-align: center;
  font-size: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '32px' : '64px'};
  line-height: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '32px' : '64px'};
`

const Styledlabel = styled.span<{ breakPoint: Breakpoint }>`
  font-weight: 700;
  font-family: OZIK-BOLD;
  text-align: center;
  margin-bottom: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '20px' : '0px'};
  width: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '164px' : '319px'};
  font-size: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '24px' : '32px'};
  width: ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) ? '164px' : '319px'};
`

export default SecondHandSearchSection
