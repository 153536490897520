import axios from 'axios'
import firebase from 'firebase'

const cloudFunctionURL = 'https://europe-west2-ask-loma.cloudfunctions.net'

const analytics = firebase.analytics()

/**
 * Method to return an array of listings across all crawled sites.
 *
 * @param {*} query string to pass to the crawler
 * @returns array of normalised data
 */
export const fetchAllCrawledData = async (query) => {
  analytics.logEvent('search', { search_term: query })
  try {
    const response = await axios.get(
      `https://search-55aggr6wta-nw.a.run.app?query=${query}`
    )
    return {
      data: response.data.data.map((item) => ({
        ...item,
        id: item.url,
        image: item.images,
        site: item.source,
        price: parseFloat(item.price),
      })),
      counts: response.data.counts,
    }
  } catch (error) {
    return { error }
  }
}

/**
 * Invoke classify endpointd and merge left product categories to actual product items
 *
 * @param {*} products
 * @returns
 */
export const classify = async (products) => {
  try {
    const response = await axios.post(
      'https://classification-55aggr6wta-lm.a.run.app',
      { products },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    const mergedZip = response.data.data.map((classifiedItem) => ({
      ...products.find((p) => p.id === classifiedItem.id),
      category: classifiedItem.category,
    }))

    return { ok: true, products: mergedZip, counts: response.data.counts }
  } catch (exception) {
    return { ok: false, exception }
  }
}
