import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div<{ isHidden: boolean }>`
  position: fixed;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`

export default Overlay
