import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const SIZE_XS: Breakpoint = 'xs'
const SIZE_SM: Breakpoint = 'sm'
const SIZE_MD: Breakpoint = 'md'
const SIZE_LG: Breakpoint = 'lg'
const SIZE_XL: Breakpoint = 'xl'
const SIZE_XXL: Breakpoint = 'xxl'

// break points for phones and tablets
export const SMALLER_BREAKPOINTS: Breakpoint[] = [SIZE_XS, SIZE_SM, SIZE_MD]

// break points for phones
export const PHONE_BREAKPOINTS: Breakpoint[] = [SIZE_XS, SIZE_SM]

// break points for tablet
export const TABLET_BREAKPOINT: Breakpoint[] = [SIZE_MD]

const resolveBreakpoint = (width: number): Breakpoint => {
  if (width < 576) {
    return SIZE_XS
  }
  if (width >= 576 && width < 768) {
    return SIZE_SM
  }
  if (width >= 768 && width < 992) {
    return SIZE_MD
  }
  if (width >= 992 && width < 1200) {
    return SIZE_LG
  }
  if (width >= 1200 && width < 1440) {
    return SIZE_XL
  }
  if (width >= 1440) {
    return SIZE_XXL
  }
}

/** Get Media Query Breakpoints in React */
const useBreakpoint = (): Breakpoint => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = debounce(() => {
      setSize(resolveBreakpoint(window.innerWidth))
    }, 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return size
}

export default useBreakpoint
