import CloseX from 'assets/img/close.svg'
import Button from 'components/buttons'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  message: string
}

export default function Alert({ title, message }: Props) {
  return (
    <Container>
      <Background>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <Row>
          <ButtonContainer>
            <Button text="Send" type="primary" style={{ width: '100%' }} />
          </ButtonContainer>
          <ButtonContainer style={{ marginLeft: 10 }}>
            <Button text="No" type="secondary" style={{ width: '100%' }} />
          </ButtonContainer>
        </Row>
      </Background>
      <CloseContainer>
        <img src={CloseX} alt="close" />
      </CloseContainer>
    </Container>
  )
}

const ButtonContainer = styled.div`
  min-width: 80px;
`

const Container = styled.div`
  position: relative;
  max-width: 294px;
  overflow: visible;
  padding-top: 18px;
  padding-right: 18px;
  margin-left: 18px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 50%;
`

const CloseContainer = styled.button`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 48px;
  height: 48px;
  background: #f15b3b;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const Background = styled.div`
  position: relative;
  min-width: 294px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`

const Title = styled.span`
  font-family: OZIK-Black;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  /* or 111% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
  margin-bottom: 21px;
`

const Message = styled.span`
  /* 14 - Body */

  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */

  text-align: center;

  color: #000000;
  margin-bottom: 14px;
`
