import Apple from 'assets/img/apple.png'
import Google from 'assets/img/google.png'
import FeathersIcon from 'feather-icons-react'
import { useContainerDimensions } from 'hooks/useContainerDimensions'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'

interface Props {
  text: string
  type?: 'primary' | 'secondary' | 'tertiary'
  icon?: string
  iconSize?: number
  buttonColour?: string
  textAndIconColour?: string

  // Overrides
  social?: 'google' | 'apple'
  style?: React.CSSProperties

  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const SocialIcons = {
  google: Google,
  apple: Apple,
}

export default function Button({
  text,
  type = 'primary',
  icon,
  iconSize = 24,
  buttonColour,
  textAndIconColour = 'black',
  style,
  social,

  onClick = () => {},
}: Props) {
  const componentRef = useRef()
  const { width, height } = useContainerDimensions(componentRef)

  // Icon Overrides
  const Icon = useMemo(() => {
    if (icon) {
      return (
        <FeathersIcon
          icon={icon}
          size={iconSize}
          textAndIconColour={textAndIconColour}
          style={{
            marginRight: 8,
          }}
        />
      )
    }

    if (social) {
      return (
        <img
          src={SocialIcons[social]}
          alt={social}
          style={{
            height: iconSize * 0.7,
            width: iconSize * 0.7,
            marginRight: 8,
          }}
        />
      )
    }

    return null
  }, [icon, iconSize, textAndIconColour, social])

  // Style overrides (e.g. if social)

  return (
    <Parent>
      <Container
        ref={componentRef}
        type={type}
        social={social}
        button={buttonColour}
        onClick={onClick}
        style={style}
      >
        {Icon}
        <Text social={social}>{text}</Text>
      </Container>
      <ShadowContainer style={{ width, height, marginTop: -(height * 0.9) }} />
    </Parent>
  )
}

const Colors = {
  primary: '#61e4c5',
  secondary: '#F15B3B',
  tertiary: '#FFBD12',
  google: '#80BAFF',
  apple: '#474B64',
}

const Parent = styled.div`
  height: 43px;
`

const Container = styled.button`
  position: relative;
  height: 38px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  background: ${({ type, social }) => (social ? Colors[social] : Colors[type])};
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
`
// @TODO Fix shadows on hover - we may not need an extra div
const ShadowContainer = styled.div`
  position: absolute;
  height: 38px;
  margin-top: -36px;
  background: #000000;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
`

const Text = styled.span`
  height: 14px;
  /* 17 - DIN 2014 ExtraBold */
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 14px;
  /* identical to box height, or 82% */
  text-align: center;
  color: ${({ social }) => (social === 'apple' ? '#FFFFFF' : '#18191f')};
`
