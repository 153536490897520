import { classify, fetchAllCrawledData } from 'api'
import { PriceRange } from 'components/Filters/components/PriceFilter'
import { SiteType } from 'components/Filters/components/SiteFilter'
import { inRange, uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

export type ListingType = {
  description: string
  id: string
  image: string[]
  images: string[]
  price: number
  site: string
  source: string
  url: string
  title: string
  category: string
}

export const DEFAULT_CATEGORY_COUNTS = {
  Clothing: 0,
  Books: 0,
  Home: 0,
  Electronics: 0,
  Other: 0,
}

export const DEFAULT_SITE_COUNTS = {
  atika: 0,
  backmarket: 0,
  beyondretro: 0,
  byrotation: 0,
  cashconverters: 0,
  cex: 0,
  depop: 0,
  ebay: 0,
  etsy: 0,
  fatllama: 0,
  freegle: 0,
  freecycle: 0,
  gumtree: 0,
  haru: 0,
  hewi: 0,
  musicmagpie: 0,
  oxfam: 0,
  paperclip: 0,
  preloved: 0,
  rokit: 0,
  thriftify: 0,
  thriftplus: 0,
  vinted: 0,
  vinterior: 0,
}

/**
 * Automatically invokes the crawlers in Google Cloud Functions if query changes
 *
 * @param query string param to pass to crawlers
 * @param categoriesToShow filter categories to show
 * @param sitesToShow filter sites to show
 * @returns { listings, error, categories, counts, crawling, classifying }
 */
export function useCrawlers(
  query: string,
  categoriesToShow: string[],
  filteredSites: SiteType[],
  priceRange: PriceRange
) {
  const [listings, setListings] = useState<Partial<ListingType[]>>([])
  const [error, setError] = useState(undefined)
  const [categories, setCategories] = useState(
    Object.keys(DEFAULT_CATEGORY_COUNTS)
  )
  const [counts, setCounts] = useState([])
  const [categoryCounts, setCategoryCounts] = useState(
    Object.keys(DEFAULT_CATEGORY_COUNTS).map(
      (key) => DEFAULT_CATEGORY_COUNTS[key]
    )
  )

  const [crawling, setCrawling] = useState(false)
  const [classifying, setClassifying] = useState(false)

  useEffect(() => {
    const fetchAllCrawl = async () => {
      setListings([])
      setError(undefined)

      setCrawling(true)
      // Fetch all crawl first
      const {
        data,
        error: apiError,
        counts: apiCounts,
      } = await fetchAllCrawledData(query)

      // Set data (temp so we can display)
      if (data) {
        setListings(data)
        const siteCountsMerge = { ...DEFAULT_SITE_COUNTS, ...apiCounts }
        setCounts(
          Object.keys(siteCountsMerge).map((key) => siteCountsMerge[key])
        )
      } else if (apiError) {
        setError(apiError)
      }

      setCrawling(false)
      setClassifying(true)

      // Post classify
      const { ok, products, counts: catCounts } = await classify(data)

      if (ok && !!products) {
        setListings(products)

        const catCountsMerge = { ...DEFAULT_CATEGORY_COUNTS, ...catCounts }

        setCategoryCounts(
          Object.keys(catCountsMerge).map((key) => catCountsMerge[key])
        )
      }
      setClassifying(false)
    }

    fetchAllCrawl()
  }, [
    query,
    setCategories,
    setError,
    setListings,
    setCrawling,
    setClassifying,
    setCounts,
    setCategoryCounts,
  ])

  const resolvedListings: ListingType[] = useMemo(() => {
    // categories not empty, not crawling, and not classifying (We're ready to filter)

    //  get site names to be filtered
    const toBeFilteredSites = filteredSites.map((site) => site.name)
    const maxPrice = priceRange.max <= 0 ? 99999999 : priceRange.max
    let filteredListings

    if (!crawling && categories.length !== 0) {
      if (!classifying) {
        filteredListings = listings.filter(
          (listing) =>
            categoriesToShow.includes(listing.category) &&
            !toBeFilteredSites.includes(listing.site) &&
            inRange(listing.price, priceRange.min, maxPrice)
        )
      } else {
        filteredListings = listings.filter(
          (listing) =>
            !toBeFilteredSites.includes(listing.site) &&
            inRange(listing.price, priceRange.min, maxPrice)
        )
      }

      return filteredListings.map((item) => ({
        ...item,
        images: [...new Set([...item.image, ...item.images].filter((i) => i))],
      }))
    }

    return listings
  }, [
    categoriesToShow,
    filteredSites,
    priceRange,
    crawling,
    classifying,
    categories,
    listings,
  ])

  const resolvedProductSites: SiteType[] = useMemo(() => {
    const toBeFilteredSites = filteredSites.map((site) => site.name)

    const productSites = Object.keys(DEFAULT_SITE_COUNTS).map<
      Partial<SiteType>
    >((ps) => ({
      name: ps,
      isActive: !toBeFilteredSites.includes(ps),
      numberOfResults: listings.filter((listing) => listing.site === ps).length,
    }))

    return uniqBy(productSites, 'name')
  }, [filteredSites, listings])

  return {
    listings: resolvedListings,
    error: error,
    categories: categories,
    crawling: crawling,
    classifying: classifying,
    counts: counts,
    categoryCounts: categoryCounts,
    sites: resolvedProductSites,
  }
}
