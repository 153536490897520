import { generateUserName } from 'config/helper'
import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyClS0SzJBol_UetUtqds1rmbByjVmfSwA0',
  authDomain: 'ask-loma.firebaseapp.com',
  databaseURL: 'https://ask-loma-default-rtdb.firebaseio.com',
  projectId: 'ask-loma',
  storageBucket: 'ask-loma.appspot.com',
  messagingSenderId: '303496383503',
  appId: '1:303496383503:web:f8b98bdf238a969296277a',
  measurementId: 'G-3WX1KFFLV0',
}

const sampleFeaturedDeals = [
  {
    title: 'Barbour Jersey 66',
    desc: 'Cascade Sports Cap with examples words to fill the description',
    price: 120,
    site: 'etsy',
    url: 'etsy.com',
    image: 'https://i.ebayimg.com/images/g/MosAAOSw2OlfsDMY/s-l1600.jpg',
  },
]

firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()
const storage = firebase.storage()
const db = firebase.firestore()
const dateNow = firebase.firestore.FieldValue.serverTimestamp()
const featuredCollection = 'Featured Deals'
const userCollection = 'Users'

// Initialize Performance Monitoring and get a reference to the service
firebase.performance()

export const getFeaturedDeals = async () => {
  try {
    const itemRef = await db.collection(featuredCollection).get()
    const featuredDeals = []
    itemRef.forEach((doc) => {
      if (doc.exists) featuredDeals.push(doc.data())
    })
    return featuredDeals
  } catch (exception) {
    return []
  }
}

export const getUserDetails = async (userId) => {
  try {
    const userRef = db.collection(userCollection).doc(userId)

    const user = await userRef.get()

    if (user.exists) {
      return {
        ...user.data(),
        id: user.id,
      }
    }
  } catch (error) {
    return null
  }
}

export const loginAccount = async (creds) => {
  const { username, password } = creds

  const userSnapshot = await db
    .collection(userCollection)
    .where('username', '==', username)
    .get()
  let user = { email: '', password: '' }
  userSnapshot.docs.map((doc) => (user = { ...doc.data() } as any))

  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(user.email, password)
      .then(() => resolve(user))
      .catch((error) => reject(error))
  })
}

const getUniqueUserName = async (fullname, increment = '') => {
  const tempUserName = generateUserName(fullname)
  const newUserName = tempUserName + increment
  const userSnapshot = await db
    .collection(userCollection)
    .where('username', '==', newUserName)
    .get()
  let usernameExists = ''
  userSnapshot.docs.map((doc) => (usernameExists = doc.data().username))

  if (usernameExists) {
    return getUniqueUserName(tempUserName, `${Number(increment) + 1}`)
  }
  return newUserName
}

export const loginAccountWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider()

  return auth
    .signInWithPopup(googleProvider)
    .then(async (res) => {
      const { user } = res
      const userRef = await db.collection(userCollection).doc(user.uid)
      const userDoc = await userRef.get()

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!userDoc.exists) {
          const newUser = await getUniqueUserName(user.displayName)
          db.collection(userCollection)
            .doc(user.uid)
            .set({
              id: user.uid,
              name: user.displayName,
              username: newUser,
              email: user.email,
              photo: '',
              about: 'Tell us a little bit about yourself.',
              likes: [],
              updatedAt: dateNow,
              createdAt: dateNow,
            })
            .then(async () => {
              const resUser = await getUserDetails(user.uid)
              resolve(resUser)
            })
            .catch((error) => reject(error))
        } else {
          const resUser = await getUserDetails(user.uid)
          resolve(resUser)
        }
      })
    })
    .catch((error) => error)
}

export const loginAccountWithApple = async () => {
  const appleProvider = new firebase.auth.OAuthProvider('apple.com')

  return auth
    .signInWithPopup(appleProvider)
    .then(async (res) => {
      const { user } = res
      const userRef = await db.collection(userCollection).doc(user.uid)
      const userDoc = await userRef.get()

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (!userDoc.exists) {
          const newUser = await getUniqueUserName(user.displayName)
          db.collection(userCollection)
            .doc(user.uid)
            .set({
              id: user.uid,
              name: user.displayName,
              username: newUser,
              email: user.email,
              photo: '',
              about: 'Tell us a little bit about yourself.',
              likes: [],
              updatedAt: dateNow,
              createdAt: dateNow,
            })
            .then(async () => {
              const resUser = await getUserDetails(user.uid)
              resolve(resUser)
            })
            .catch((error) => reject(error))
        } else {
          const resUser = await getUserDetails(user.uid)
          resolve(resUser)
        }
      })
    })
    .catch((error) => error)
}

export const registerAccount = async (creds) => {
  const { name = '', username, email, password } = creds

  const userSnapshot = await db
    .collection(userCollection)
    .where('username', '==', username)
    .get()
  let usernameExists = ''
  userSnapshot.docs.map((doc) => (usernameExists = doc.data().username))

  return new Promise((resolve, reject) => {
    if (usernameExists) {
      reject({ code: 'auth/username-exists' })
    } else {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          await db
            .collection(userCollection)
            .doc(user.uid)
            .set({
              id: user.uid,
              name,
              username,
              email,
              photo: '',
              about: 'Tell us a little bit about yourself.',
              likes: [],
              updatedAt: dateNow,
              createdAt: dateNow,
            })
            .then(async () => {
              const resUser = await getUserDetails(user.uid)
              resolve(resUser)
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    }
  })
}

export const removeAuth = () => {
  auth.signOut().then(() => console.log('User signed out!'))
}

const uploadAndGetURL = async (imgFile) => {
  const storageRef = storage.ref()
  const fileRef = storageRef.child(imgFile.name)
  const metadata = { contentType: imgFile.type }

  try {
    await fileRef.put(imgFile, metadata)
    const resURL = await fileRef.getDownloadURL()
    return resURL
  } catch (err) {
    return err
  }
}

export const saveProfileChanges = async (file, userData, userId) => {
  let userPhotoURL = userData.photo
  try {
    if (file) userPhotoURL = await uploadAndGetURL(file)
    const userNewData = {
      ...userData,
      photo: userPhotoURL,
      updatedAt: dateNow,
    }
    await db.collection(userCollection).doc(userId).update(userNewData)
    return await getUserDetails(userId)
  } catch (err) {
    console.log(err)
    return err
  }
}

export const updateUserLikes = async (userId, likes) => {
  try {
    const parsedData = likes.map((item) => {
      if (!item.description) {
        return { ...item, description: '' }
      }
      return item
    })
    await db
      .collection(userCollection)
      .doc(userId)
      .update({ likes: parsedData })
    return await getUserDetails(userId)
  } catch (err) {
    console.log(err)
    return err
  }
}
