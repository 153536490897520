import React from 'react'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

interface FilterBarProps {
  onFilterBarClicked?: () => void
}

const FilterBar = ({ onFilterBarClicked }: FilterBarProps) => (
  <FilterBarContainer onClick={onFilterBarClicked}>
    <FeatherIcon icon="filter" size={16} />
    <FilterText>Filter</FilterText>
  </FilterBarContainer>
)

const FilterBarContainer = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  padding: 9.2px 6px;
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 44px;
  box-shadow: 0px 2px 0px #18191f;
  background-color: white;
`

const FilterText = styled.span`
  margin-left: 12px;
  font-size: 14px;
  font-family: 'DIN 2014';
`

export default FilterBar
