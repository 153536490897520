import 'assets/css/animate.min.css'
// styles
import 'assets/css/bootstrap.min.css'
import 'assets/css/main.css'
import { LogIn } from 'components/modals'
import { loginAccountWithApple, loginAccountWithGoogle } from 'firestore'
import useAuthentication from 'hooks/useAuthentication'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import NavBar from 'components/NavbarV2'
import FooterV2 from 'components/FooterV2'
// pages
import HomePage from 'views/HomePage'
import styled from 'styled-components'
import ResultsPage from 'views/ResultsPage'
import ProfilePage from 'views/ProfilePage'
import ExternalPage from 'views/ExternalPage'

export default () => {
  const {
    showLogInModal,
    setShowLogInModal,
    loginWithEmail,
    signUpWithEmail,
    error,
  } = useAuthentication()

  return (
    <BrowserRouter>
      {showLogInModal && (
        <Backdrop>
          <Center>
            <LogIn
              onClose={() => setShowLogInModal(false)}
              loginWithGoogle={loginAccountWithGoogle}
              loginWithApple={loginAccountWithApple}
              // @TODO We need to add a new UI for sign up OR just us the existing SignUp Form
              loginWithEmail={loginWithEmail}
              signUpWithEmail={signUpWithEmail}
              error={error}
            />
          </Center>
        </Backdrop>
      )}
      <NavBar />
      <Switch>
        <Route exact path="/" render={(props) => <HomePage {...props} />} />
        <Route
          exact
          path="/my-profile"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          exact
          path="/results"
          render={(props) => <ResultsPage {...props} />}
        />
        <Route
          exact
          path="/about"
          render={(props) => (
            <ExternalPage {...props} iframeSrc="https://aboutloma.carrd.co/" />
          )}
        />
        <Route
          exact
          path="/missing-sites"
          render={(props) => (
            <ExternalPage
              {...props}
              iframeSrc="https://requestsite.carrd.co/"
            />
          )}
        />
      </Switch>
      <FooterV2 />
    </BrowserRouter>
  )
}

const Backdrop = styled.div`
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const Center = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
