import FeathersIcon from 'feather-icons-react'
import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLProps<HTMLInputElement> {
  icon?: string
  error?: string
}

export default function Input(props: Props) {
  const { icon, error } = props
  return (
    <Container>
      <InputContainer error={error}>
        {icon && (
          <FeathersIcon icon={icon} color={error ? '#F15B3B' : '#18191f'} />
        )}
        <InputBare {...props} style={{ marginLeft: icon ? 10 : 0 }} />
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  border: ${(props) =>
    props.error ? '1px solid #F15B3B' : '1px solid #18191f'};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 11px;
  align-items: center;
  max-height: 46px;
`

const InputBare = styled.input`
  border: 0px;
  flex-grow: 1;
  /* 14 - Body */

  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  align-items: center;

  /* Colors / Black */

  color: ${(props) => (props.error ? '#F15B3B' : '#18191f')};
  outline: none;
`

const ErrorMessage = styled.span`
  /* 14 - Body */

  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #f15b3b;
  align-self: center;
  justify-self: center;
  margin-top: 7px;
`
