const DEFAULT_KEYWORDS = [
  'y2k',
  'atomic habits',
  'eames chair',
  'sally rooney',
  'ipad mini',
  'patagonia',
  'airpods',
  'carhartt',
]

export function useTryKeywords() {
  return {
    keywords: DEFAULT_KEYWORDS,
  }
}
