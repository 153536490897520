import FeatherIcon from 'feather-icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

interface CheckboxProps {
  checked: boolean
  type: 'success' | 'warning'
  label?: string
  iconSize?: number
  size?: number
  onToggle?: (val: boolean) => void
}

const Checkbox = (props: CheckboxProps) => {
  const { checked, type, label, iconSize = 12, size = 20, onToggle } = props

  const [isChecked, setIsChecked] = useState(checked)

  const toggleChecked = () => setIsChecked(!isChecked)

  const resolvedSize = useMemo(() => size * 1.3, [size])

  useEffect(() => {
    onToggle(isChecked)
  }, [isChecked])

  return (
    <CheckboxContainer>
      {isChecked ? (
        <div
          aria-hidden="true"
          style={{
            display: 'flex',
            border: '1px solid',
            borderRadius: '5px',
            borderColor: type === 'success' ? '#000' : '#FFBD12',
            backgroundColor: type === 'success' ? '#00C6AE' : '#FFF4CC',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            width: `${resolvedSize}px`,
            height: `${resolvedSize}px`,
          }}
          onClick={toggleChecked}
        >
          <FeatherIcon
            icon="check"
            size={iconSize * 1.3}
            style={{ color: type === 'success' ? '#000' : '#FFBD12' }}
          />
        </div>
      ) : (
        <div
          aria-hidden="true"
          style={{
            border: '2px solid #969BAB',
            borderRadius: '8px',
            cursor: 'pointer',
            width: `${resolvedSize}px`,
            height: `${resolvedSize}px`,
          }}
          onClick={toggleChecked}
        />
      )}
      {label && <span style={{ marginLeft: '8px' }}>{label}</span>}
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

export default Checkbox
