import { device } from 'config/constants'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export type PriceRange = {
  min: string | number
  max: string | number
}

interface PriceFilterProps {
  priceRange: PriceRange
  onChangePriceRange?: (priceRange: PriceRange) => void
}

const PriceFilter = ({ priceRange, onChangePriceRange }: PriceFilterProps) => {
  const [minPrice, setMinPrice] = useState(priceRange.min)
  const [maxPrice, setMaxPrice] = useState(priceRange.max)

  useEffect(() => {
    if (onChangePriceRange) {
      onChangePriceRange({ min: minPrice, max: maxPrice })
    }
  }, [minPrice, maxPrice])

  const handleChangeMinPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  TODO: put validation if the input has letters, special characters, etc...

    if (e.currentTarget.value !== '') setMinPrice(e.currentTarget.value)
  }

  const handleChangeMaxPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  TODO: put validation if the input has letters, special characters, etc...
    //  TODO: put validation if number is greater than the minimum input

    if (e.currentTarget.value !== '') setMaxPrice(e.currentTarget.value)
  }

  return (
    <PriceContainer>
      <LabelContainer>
        <Label>Min Price</Label>
        <Label>Max Price</Label>
      </LabelContainer>
      <InnerPriceContainer>
        <MinMaxPriceContainer
          style={{
            flexGrow: 1,
            paddingLeft: '6px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>£</span>
          <PriceInput
            type="number"
            min={0}
            value={minPrice}
            onChange={handleChangeMinPrice}
          />
        </MinMaxPriceContainer>
        <MinMaxPriceContainer
          style={{
            flexGrow: 1,
            paddingLeft: '6px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>£</span>
          <PriceInput
            type="number"
            min={0}
            value={maxPrice}
            onChange={handleChangeMaxPrice}
          />
        </MinMaxPriceContainer>
      </InnerPriceContainer>
    </PriceContainer>
  )
}

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Gidole;
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const Label = styled.div`
  flex-grow: 1;
  margin-left: 6px;
`

const InnerPriceContainer = styled.div`
  border: 1px solid #969bab;
  border-radius: 8px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  height: 36px;
  overflow: hidden;
  background-color: white;
`

const MinMaxPriceContainer = styled.div`
  flex-grow: 1;
  padding-left: 6px;
  display: flex;
  align-items: center;
`

const PriceInput = styled.input`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: 0px;
  outline: none;
  @media ${device.mobileS} {
    max-width: 8.5rem;
  }
  @media ${device.laptop} {
    max-width: 6rem;
  }
`

export default PriceFilter
