import useAuthentication from 'hooks/useAuthentication'
import useBreakpoint, { PHONE_BREAKPOINTS } from 'hooks/useBreakPoint'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import CoverImg from '../../assets/img/cover.png'
import RecentFindsSection from './components/RecentFindsSection'
import SearchSection from './components/SearchSection'
import SecondHandSearchSection from './components/SecondHandSearchSection'
import SignupSection from './components/SignupSection'
import SitesMarqueeSection from './components/SitesMarqueeSection'
import TestimonySection from './components/TestimonySection'

interface HomePageV2Props {}

const HomePage = (props: HomePageV2Props) => {
  const br = useBreakpoint()
  const { unauthenticated } = useAuthentication()
  return (
    <Container
      fluid
      className="p-0"
      style={{ marginTop: PHONE_BREAKPOINTS.includes(br) ? '0px' : '70px' }}
    >
      <Row>
        <Col lg={6} md={12} sm={12} xs={12} className="p-0">
          <img
            src={CoverImg}
            alt="cover"
            className="w-100"
            style={{ objectFit: 'contain' }}
          />
        </Col>
        <Col lg={6} md={12} sm={12} xs={12} className="p-0 order-lg-first">
          <SearchSection />
        </Col>
      </Row>
      <Container fluid className="px-0 ">
        <SitesMarqueeSection />
        <RecentFindsSection />
        <SecondHandSearchSection />
        <TestimonySection />
        {unauthenticated && <SignupSection />}
      </Container>
    </Container>
  )
}

export default HomePage
