import FeatherIcon from 'feather-icons-react'
import useAuthentication from 'hooks/useAuthentication'
import useBreakpoint, {
  PHONE_BREAKPOINTS,
  SMALLER_BREAKPOINTS,
} from 'hooks/useBreakPoint'
import useNavigation from 'hooks/useNavigation'
import React, { useCallback, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Row,
} from 'reactstrap'
import styled from 'styled-components'
import LomaLogo from '../Logo'
import SearchBox from '../SearchBox'

interface NavBarProps {
  onSearch?: (searchValue: string) => void
}

const NavBar = (props: NavBarProps) => {
  const { onSearch } = props
  const br = useBreakpoint()
  const location = useLocation()
  const { results, profile, home, query, about } = useNavigation()

  const { setShowLogInModal, unauthenticated, logoutAccount } =
    useAuthentication()

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)

  const toggleNavMenu = () => setIsNavMenuOpen(!isNavMenuOpen)

  const handleSearchIconClicked = () => {
    if (location.pathname === '/results') {
      window.scrollTo(0, 0)
    } else if (PHONE_BREAKPOINTS.includes(br)) window.scrollTo(0, 400)
    else window.scrollTo(0, 600)
  }

  const defaultSearch = useCallback((q: string) => results(q), [results])

  return (
    <Navbar
      expand="lg"
      {...(location.pathname === '/' &&
        !PHONE_BREAKPOINTS.includes(br) && { fixed: 'top' })}
      {...(location.pathname === '/results' && { fixed: 'top' })}
      className={SMALLER_BREAKPOINTS.includes(br) ? 'px-0' : 'px-2'}
      style={{ backgroundColor: 'black' }}
    >
      <Container fluid className="px-0 py-2">
        {!SMALLER_BREAKPOINTS.includes(br) ? (
          // Desktop View
          <Row className="pl-4 w-100">
            <Col
              className="col-3 p-0 d-flex align-items-center"
              onClick={home}
              style={{ cursor: 'pointer' }}
            >
              <LomaLogo />
            </Col>
            {location.pathname === '/results' && (
              <Col className="col-4.5">
                <SearchBox
                  type="dark"
                  onSearch={onSearch || defaultSearch}
                  value={query}
                />
              </Col>
            )}
            <Col className="d-flex align-items-center">
              <Nav className="ml-auto">
                <NavItem onClick={about}>
                  <NavLink
                    to="#"
                    style={{ color: 'white', fontFamily: 'DIN 2014' }}
                  >
                    About
                  </NavLink>
                </NavItem>
                {!unauthenticated && (
                  <NavItem>
                    <NavLink
                      to="/my-profile"
                      style={{ color: 'white', fontFamily: 'DIN 2014' }}
                    >
                      Account
                    </NavLink>
                  </NavItem>
                )}
                {unauthenticated && (
                  <NavItem>
                    <Button
                      outline
                      onClick={() => {
                        setShowLogInModal(true)
                      }}
                      style={{
                        backgroundColor: 'black',
                        borderColor: 'white',
                        color: 'white',
                        padding: '2px 24px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Log in
                    </Button>
                  </NavItem>
                )}
                {!unauthenticated && (
                  <NavItem>
                    <Button
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '2px 24px',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={logoutAccount}
                    >
                      Sign Out
                    </Button>
                  </NavItem>
                )}
              </Nav>
            </Col>
          </Row>
        ) : (
          // Mobile/Tablet View
          <Row className="pl-3 w-100">
            <Col className="col-2 p-0 pl-3">
              <NavbarToggler onClick={toggleNavMenu}>
                <div>
                  <MenuBar />
                  <MenuBar />
                  <MenuBar />
                </div>
              </NavbarToggler>
            </Col>
            <Col
              className="p-0 d-flex align-items-center justify-content-center"
              onClick={home}
              style={{ cursor: 'pointer' }}
            >
              <LomaLogo />
            </Col>
            <Col className="col-2 d-flex align-items-center justify-content-end p-0">
              <div
                role="button"
                tabIndex={0}
                className="pr-2"
                onClick={handleSearchIconClicked}
              >
                <FeatherIcon
                  icon="search"
                  size={25}
                  style={{ color: 'white' }}
                />
              </div>
              <div role="button" tabIndex={0} onClick={profile}>
                <FeatherIcon icon="user" size={25} style={{ color: 'white' }} />
              </div>
            </Col>
            <StyledCollapse navbar isOpen={isNavMenuOpen}>
              <Container
                fluid
                className="bg-light d-flex flex-column align-items-center"
              >
                <NavLink
                  onClick={about}
                  to="#"
                  className="text-dark py-2"
                  style={{ color: 'white', fontFamily: 'DIN 2014' }}
                >
                  About
                </NavLink>
                {!unauthenticated && (
                  <NavLink
                    to="/my-profile"
                    className="text-dark py-2"
                    style={{ color: 'white', fontFamily: 'DIN 2014' }}
                  >
                    Account
                  </NavLink>
                )}
              </Container>
            </StyledCollapse>
          </Row>
        )}
      </Container>
    </Navbar>
  )
}

const MenuBar = styled.div`
  width: 28px;
  height: 1.5px;
  background-color: white;
  margin: 6px 0;
`
const StyledCollapse = styled(Collapse)`
  position: absolute;
  margin-top: 56px;
  width: 100%;
`

export default NavBar
