import { getFeaturedDeals } from 'firestore'
import { useEffect, useState } from 'react'

const DEFAULT_LISTINGS_PER_DEVICE = {
  xs: 6,
  sm: 6,
  md: 6,
  lg: 10,
  xl: 10,
  xxl: 10,
}

/**
 * Returns featured listings - the number of listings is dependent on the breakpoint & config above
 *
 * @param breakpoint
 * @param LISTINGS_PER_DEVICE
 * @returns
 */
export default function useFeaturedListings(
  breakpoint,
  LISTINGS_PER_DEVICE = DEFAULT_LISTINGS_PER_DEVICE
) {
  const [featuredListings, setFeaturedListings] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const deals = await getFeaturedDeals()
      const sourceDeals = deals.map((deal) => ({
        ...deal,
        siteName: new URL(deal.url).hostname.split('.')[1],
        images: [deal.image],
      }))
      setFeaturedListings(sourceDeals)
    }

    fetch()
  }, [setFeaturedListings])

  return {
    featuredListings: featuredListings.slice(
      0,
      LISTINGS_PER_DEVICE[breakpoint]
    ),
  }
}
