import Checkbox from 'components/Checkbox'
import { device } from 'config/constants'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

interface Props {
  categories: string[]
  counts: number[]
  isLoading?: boolean
  onCategoriesSelectedChange: (cats: string[]) => void
  headerTitle?: string
}

export default function Categories({
  categories,
  counts,
  isLoading = false,
  onCategoriesSelectedChange,
  headerTitle = 'Categories',
}: Props) {
  const [categoriesToShow, setCategoriesToShow] = useState(categories)

  const onToggle = useCallback(
    (val: string, toggled: boolean) => {
      if (!toggled) {
        setCategoriesToShow(categoriesToShow.filter((cat) => cat !== val))
      } else {
        // spread using set to make sure it's unique
        setCategoriesToShow([...new Set([...categoriesToShow, val])])
      }
    },
    [categoriesToShow, setCategoriesToShow]
  )

  useEffect(() => {
    onCategoriesSelectedChange(categoriesToShow)
  }, [categoriesToShow, onCategoriesSelectedChange])

  return (
    <Container>
      <Header className="mb-2">{headerTitle}</Header>
      {categories.map((val: string, idx: number) => (
        <Row key={idx} className="d-flex py-1">
          <Col className="col-8">
            <LabelContainer>
              <CategoryText>{val}</CategoryText>
              <CountTextContainer>
                <CountText className={isLoading ? 'loading' : ''}>
                  {isLoading ? '' : counts[idx]}
                </CountText>
              </CountTextContainer>
            </LabelContainer>
          </Col>
          <Col className="col-4 d-flex justify-content-end">
            <Checkbox
              checked={true}
              type="warning"
              onToggle={(toggled) => onToggle(val, toggled)}
            />
          </Col>
        </Row>
      ))}
    </Container>
  )
}

const Header = styled.span`
  font-family: 'Gidole';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  /* or 78% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #000000;
`

const LabelContainer = styled.div`
  min-width: 189px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Container = styled.div`
  min-width: 308px;

  @media ${device.mobileL} {
    max-width: 100%;
    min-width: 100%;
  }
`

const CategoryText = styled.span`
  max-width: 120px;
  /* 14 - Body */

  font-family: 'Gidole';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  /* Colors / Black 800 */

  color: #474a57;
`

const CountTextContainer = styled.div`
  background: #d6fcf7;
  border-radius: 6px;
  padding: 4px;
  margin-left: 4px;
`

const CountText = styled.span`
  font-family: 'Gidole';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  padding: 0 8px;

  color: #00c6ae;
`
