/* eslint-disable no-nested-ternary */
// assets
import closeBtn from 'assets/img/close-modal.png'
import emailIcon from 'assets/img/icon-email.png'
import phoneIcon from 'assets/img/icon-phone.png'
import redirectIcon from 'assets/img/icon-redirect.png'
import userIcon from 'assets/img/icon-user.png'
import likeIcon from 'assets/img/like.png'
import { ReactComponent as ListDetails } from 'assets/img/list-detail.svg'
import { ReactComponent as ListImg } from 'assets/img/list-img.svg'
import orangeHeart from 'assets/img/orange-heart.png'
import userPlacholder from 'assets/img/user-placeholder.jpeg'
import StyledInput from 'components/partials/StyledInput'
import { colors, device } from 'config/constants'
import UserContext from 'context/UserContext'
// api functions
import { removeAuth, saveProfileChanges, updateUserLikes } from 'firestore'
import useNavigation from 'hooks/useNavigation'
import { clone } from 'lodash'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Row,
} from 'reactstrap'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ErrorModal = withReactContent(Swal)

export default (props: any) => {
  const history = useHistory()
  const context = useContext(UserContext)
  const { home } = useNavigation()
  const { user, logoutAccount, updateUser } = context
  const [isLoading, setLoading] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [isSavingLikes, setSavingLikes] = useState(false)
  const [userState, setUserState] = useState()
  const [imgFile, setImgFile] = useState()
  const [viewBy, setViewBy] = useState(true)
  const [itemCount, setItemCount] = useState(12)
  const [activeImgView, setActiveImgView] = useState()
  const [items, setItems] = useState([])
  const [activeItem, setActiveItem] = useState()
  const [activePosition, setActivePosition] = useState()
  const listContainerRef = useRef()
  const activeItemRef = useRef()

  const updatePosition = () => {
    const width = listContainerRef?.current.offsetWidth + 5
    const containerLeft = listContainerRef?.current.offsetLeft
    const itemViewportOffset =
      activeItemRef?.current?.getBoundingClientRect().left
    let left = itemViewportOffset + 5 - containerLeft
    if (left < 0) left = 0
    if (window.innerWidth <= 575) {
      setActivePosition({
        width: '100vw',
        left: `-${itemViewportOffset + 8}px`,
      })
    } else {
      setActivePosition({ width: `${width}px`, left: `-${left}px` })
    }
  }

  const updatePositionWithFullDetails = () => {
    if (activeItem) setActiveItem()
    updatePosition()
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePositionWithFullDetails)
    return () =>
      window.removeEventListener('resize', updatePositionWithFullDetails)
  }, [updatePositionWithFullDetails])

  useEffect(() => {
    setActiveImgView()
    updatePosition()

    if (activeItem && Array.isArray(activeItem.image)) {
      setActiveImgView(activeItem.image[0])
    }
  }, [activeItem])

  const viewFullDetails = (item) => {
    if (item?.id === activeItem?.id) {
      setActiveItem()
    } else {
      setActiveItem(item)
    }
  }

  const viewByDetails = () => setViewBy(true)
  const viewByImages = () => setViewBy(false)
  const loadMore = () => setItemCount(itemCount + 12)

  const onDislike = async (item) => {
    const { id } = item
    setSavingLikes(true)
    if (user) {
      let likes = clone(user.likes)
      likes = likes.filter((x) => x.id !== id)
      const res = await updateUserLikes(user.id, likes)
      if (res) {
        setSavingLikes(false)
        updateUser(res)
      }
    }
  }

  const setUserDefaults = (data) => {
    setUserState({
      email: data.email,
      username: data.username,
      name: data.name,
      photo: data.photo ? data.photo : '',
      phone: data.phone ? data.phone : '',
      about: data.about,
    })
  }

  useEffect(() => {
    if (user === null) history.push('/')
    if (user) {
      setUserDefaults(user)
      setItems(user.likes)
    }
  }, [history, user])

  const onShowError = (errorMsg) => {
    ErrorModal.fire({
      html: (
        <ErrorContainer>
          <h4>Error</h4>
          <p>{errorMsg}</p>
        </ErrorContainer>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: <img src={closeBtn} alt="Close Modal" />,
      showClass: {
        popup: 'animate__animated animate__fadeIn error_modal',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOut error_modal',
      },
    })
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const fileSize = parseFloat(file.size / (1024 * 1024)).toFixed(2)
      if (!file.type.includes('image')) {
        onShowError('File to be uploaded should only be in image format.')
      } else if (fileSize > 2) {
        onShowError('File size should not exceed 2MB.')
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          setUserState({ ...userState, photo: reader.result })
          setImgFile(file)
        }
      }
    }
  }

  const validatePhone = (val) => val.match(/^[0-9 ()+-]+$/g)

  const onProfileChange = (e) => {
    const { value, name } = e.target
    if (name === 'phone' && value) {
      const validPhone = validatePhone(value)
      if (validPhone) {
        setUserState({ ...userState, [name]: value })
      }
    } else {
      setUserState({ ...userState, [name]: value })
    }
  }

  const checkFields = () => {
    let errorMsg
    if (!userState.name) errorMsg = 'Please fill up Full name.'
    // if (!userState.phone) errorMsg = 'Please fill up Phone number.'
    if (!userState.phone && !userState.name) {
      // errorMsg = 'Please fill up Full name & Phone number.'
    }
    if (errorMsg) {
      onShowError(errorMsg)
      setLoading(false)
      return false
    }
    return true
  }

  const onSaveChanges = async () => {
    setLoading(true)
    const validFields = checkFields()

    if (validFields) {
      const userData = {
        ...userState,
        photo: user.photo,
      }
      const res = await saveProfileChanges(imgFile, userData, user.id)
      if (res) {
        updateUser(res)
        setLoading(false)
        setEditing(false)
        setImgFile()
      } else {
        onShowError(
          `There was an error saving your profile.
          Please check fields carefully.`
        )
        setLoading(false)
      }
    }
  }

  const onCancelChanges = () => {
    setUserDefaults(user)
    setImgFile()
    setEditing(false)
  }

  const onRemovePhoto = () => {
    setImgFile()
    setUserState({ ...userState, photo: '' })
  }

  const onLogout = async () => {
    home()
    await removeAuth()
    await logoutAccount()
  }
  return (
    <MainContainer>
      <ProfileContainer>
        <Container>
          <HeadPart>
            <H2>Profile</H2>
            <ActionContainer>
              {isEditing ? (
                <WhiteBtn onClick={onCancelChanges} disabled={isLoading}>
                  Cancel
                </WhiteBtn>
              ) : (
                <WhiteBtn onClick={onLogout}>Log out</WhiteBtn>
              )}
              {isEditing ? (
                <GreenBtn onClick={onSaveChanges} disabled={isLoading}>
                  Save Changes
                </GreenBtn>
              ) : (
                <GreenBtn onClick={() => setEditing(true)}>
                  Edit Profile
                </GreenBtn>
              )}
            </ActionContainer>
          </HeadPart>
          {!userState ? (
            <LoadingContainer>
              <PulseLoader color={colors.primaryBlue} />
            </LoadingContainer>
          ) : (
            <Content className={isLoading ? 'loading' : ''}>
              <Row>
                <Col md="6">
                  <ProfileInfo>
                    <div>
                      <img
                        src={userState.photo ? userState.photo : userPlacholder}
                        alt="User"
                      />
                      {isEditing && (
                        <>
                          <EditPhoto htmlFor="uploadImg">
                            Upload photo
                          </EditPhoto>
                          {!!userState.photo && (
                            <RemovePhoto onClick={onRemovePhoto}>
                              Remove photo
                            </RemovePhoto>
                          )}
                        </>
                      )}
                      <FileInput
                        type="file"
                        id="uploadImg"
                        accept="image/*"
                        onChange={onFileChange}
                      />
                    </div>
                    {isEditing ? (
                      <FormContainer>
                        <NameInput
                          value={userState?.name}
                          placeholder="Full name"
                          name="name"
                          onChange={onProfileChange}
                        />
                        <UserInfo>
                          <img src={userIcon} alt="" />
                          <UserInput
                            value={`@${userState?.username}`}
                            disabled
                            readOnly
                          />
                        </UserInfo>
                        <UserInfo>
                          <img src={emailIcon} alt="" />
                          <UserInput
                            value={userState?.email}
                            disabled
                            readOnly
                          />
                        </UserInfo>
                        <UserInfo>
                          <img src={phoneIcon} alt="" />
                          <UserInput
                            value={userState.phone}
                            name="phone"
                            placeholder="Phone number"
                            onChange={onProfileChange}
                          />
                        </UserInfo>
                      </FormContainer>
                    ) : (
                      <div>
                        <H4>{userState?.name}</H4>
                        {!!userState?.username && (
                          <UserInfo style={{ marginTop: 20 }}>
                            <img src={userIcon} alt="" />
                            <span>@{userState?.username}</span>
                          </UserInfo>
                        )}
                        {!!userState?.email && (
                          <UserInfo>
                            <img src={emailIcon} alt="" />
                            <span>{userState?.email}</span>
                          </UserInfo>
                        )}
                        {!!userState?.phone && (
                          <UserInfo>
                            <img src={phoneIcon} alt="" />
                            <span>{userState?.phone}</span>
                          </UserInfo>
                        )}
                      </div>
                    )}
                  </ProfileInfo>
                </Col>

                <Col md="6">
                  <AboutContainer>
                    <H4>About</H4>
                    {isEditing ? (
                      <UserTextArea
                        value={userState.about}
                        placeholder="Short description about you..."
                        name="about"
                        onChange={onProfileChange}
                      />
                    ) : (
                      <p>{userState.about}</p>
                    )}
                  </AboutContainer>
                </Col>
              </Row>
            </Content>
          )}
        </Container>
      </ProfileContainer>
      <UserLikesContainer>
        <div ref={listContainerRef}>
          <LikesHeader>
            <H2>Likes</H2>
            <ViewBY>
              <ListDetailsIcon
                className={viewBy ? 'active' : ''}
                onClick={viewByDetails}
              >
                <ListDetails />
              </ListDetailsIcon>
              <ListImgIcon
                className={viewBy ? '' : 'active'}
                onClick={viewByImages}
              >
                <ListImg />
              </ListImgIcon>
            </ViewBY>
          </LikesHeader>
          {items.length ? (
            !userState ? (
              <PulseLoader color={colors.primaryBlue} />
            ) : (
              <CustomRow>
                {items.slice(0, itemCount).map((item) => {
                  if (item) {
                    const key = item.id
                    return (
                      <CustomCol xl="3" lg="4" md="4" sm="6" xs="6" key={key}>
                        <CustomCard
                          onClick={() => viewFullDetails(item)}
                          className={activeItem?.id === key ? 'active' : ''}
                        >
                          <LikeIcon
                            src={likeIcon}
                            alt="Like"
                            onClick={() => onDislike(item)}
                            className={isSavingLikes ? 'saving' : ''}
                            title="Remove from likes"
                          />
                          <CustomCardImg
                            top
                            width="100%"
                            src={
                              Array.isArray(item.image)
                                ? item.image[0]
                                : item.image
                            }
                            alt="Card image cap"
                            className={viewBy ? '' : 'img-only'}
                          />
                          <CustomCardBody>
                            <CustomCardText
                              className={viewBy ? '' : 'img-only'}
                            >
                              <span>
                                {item.description?.includes(item.title) ? (
                                  item.description
                                ) : (
                                  <>
                                    {item.title}
                                    <br />
                                    {/* {item.description} */}
                                  </>
                                )}
                              </span>
                            </CustomCardText>
                            <Price>
                              <p>
                                <span
                                  style={{
                                    color: '#326401',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                  }}
                                >
                                  £{item.price.toFixed(2)}{' '}
                                </span>
                                <span
                                  style={{
                                    padding: '0 0 0 10px',
                                  }}
                                >
                                  {item.site}
                                </span>
                              </p>
                            </Price>
                          </CustomCardBody>
                        </CustomCard>

                        {activeItem?.id === key && (
                          <>
                            <ActivePointer ref={activeItemRef} />
                            <ItemDetails
                              className="active"
                              style={activePosition}
                            >
                              <ImgThumbs>
                                {Array.isArray(item.image) ? (
                                  item.image.map((img) => (
                                    <img
                                      src={img}
                                      alt="Thumbs"
                                      key={img}
                                      className={
                                        activeImgView === img ? 'active' : ''
                                      }
                                      onClick={() => setActiveImgView(img)}
                                    />
                                  ))
                                ) : (
                                  <img
                                    src={item.image}
                                    alt="Thumbs"
                                    className="active"
                                  />
                                )}
                              </ImgThumbs>
                              <LargerImgView
                                src={
                                  Array.isArray(item.image)
                                    ? activeImgView
                                    : item.image
                                }
                                alt="Card image cap"
                              />
                              <InfoContainer>
                                <ActiveTitle>{item.title}</ActiveTitle>
                                <ActiveDesc>{item.description}</ActiveDesc>
                                <LinkSource href={item.url} target="_blank">
                                  <div>
                                    <ActivePrice>
                                      £{item.price.toFixed(2)}
                                    </ActivePrice>
                                    <span>{new URL(item.url).host}</span>
                                  </div>
                                  <LinkContainer>
                                    <img src={redirectIcon} alt="Redirect" />
                                    <p>Visit site</p>
                                  </LinkContainer>
                                </LinkSource>
                              </InfoContainer>
                            </ItemDetails>
                          </>
                        )}
                      </CustomCol>
                    )
                  }
                  return null
                })}
              </CustomRow>
            )
          ) : (
            <EmptyLikes>
              {!userState ? (
                <PulseLoader color={colors.primaryBlue} />
              ) : (
                <div>
                  <img src={orangeHeart} alt="No Likes Yet" />
                  <p>
                    You don&apos;t have any liked items yet.
                    <br />
                    Search items <IndexLink href="/#">here</IndexLink>.
                  </p>
                </div>
              )}
            </EmptyLikes>
          )}

          {itemCount < items.length && (
            <LoadBtn onClick={loadMore}>Load More</LoadBtn>
          )}
        </div>
      </UserLikesContainer>
    </MainContainer>
  )
}

const H4 = styled.span`
  /* 18 - Din2014 Bold */
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  /* identical to box height, or 78% */

  letter-spacing: -0.01em;

  margin-bottom: 31px;
`

const H2 = styled.span`
  font-family: 'DIN 2014';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 28px;
`

const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const LikesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ProfileContainer = styled.div`
  background: ${colors.primaryCream};
  padding-top: 40px;
  padding-bottom: 130px;
  background-repeat: no-repeat;
  background-position: center -8%;
  background-size: cover;
  background-color: ${colors.primaryCream};
`

const EditPhoto = styled.label`
  display: block;
  cursor: pointer;
  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.dark300};
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${colors.primaryBlue};
  }
`

const RemovePhoto = styled.div`
  display: block;
  cursor: pointer;
  font-family: Gidole;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.dark300};
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: ${colors.primaryRed};
  }
`

const AboutContainer = styled.div`
  margin-bottom: 30px;
  h4 {
    font-family: Rosario-Light;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: ${colors.dark500};
    margin-top: 6px;
    margin-bottom: 16px;
  }

  p {
    font-family: Gidole;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.dark400};
  }
`

const ProfileInfo = styled.div`
  display: flex;
  margin-bottom: 30px;

  & > div {
    margin-right: 30px;
  }

  h2 {
    font-family: SourceSerifPro-Semibold;
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 34px;

    @media ${device.tablet} {
      font-size: 38px;
      line-height: 48px;
    }
  }

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center top;
  }
`

const FileInput = styled.input`
  display: none;
`

const UserInfo = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  img {
    width: auto;
    height: 100%;
    object-fit: unset;
    border-radius: 0;
    margin-right: 14px;
  }

  span {
    font-family: Gidole;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.dark400};
  }
`

const HeadPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 50px;

  h2 {
    flex: 1;
  }

  @media ${device.mobileXL} {
    flex-direction: row;
    text-align: left;
  }
`

const Content = styled.div`
  width: 100%;
  transition: opacity 0.1s ease-in-out;

  &.loading {
    pointer-events: none;
    opacity: 0.4;
  }
`

const WhiteBtn = styled(Button)`
  border-color: ${colors.primaryCream} !important;
  background-color: ${colors.primaryCream} !important;
  box-shadow: none !important;
  color: ${colors.dark500} !important;
  font-family: Gidole;
  font-size: 16px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 11px 14px;
  width: 100%;
  max-width: unset;
  transition: all 0.15s ease-in-out;
  display: inline-block;
  margin-right: 15px;
  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  @media ${device.mobileXL} {
    max-width: 115px;
  }
`

const GreenBtn = styled(Button)`
  border-color: ${colors.primaryGreen} !important;
  background-color: ${colors.primaryGreen} !important;
  box-shadow: none !important;
  color: white !important;
  font-family: Gidole;
  font-size: 16px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 11px 14px;
  width: 100%;
  max-width: unset;
  display: inline-block;
  transition: all 0.15s ease-in-out;
  &:hover,
  &:focus {
    filter: brightness(0.93);
  }

  @media ${device.mobileXL} {
    max-width: 125px;
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;

  @media ${device.mobileXL} {
    justify-content: flex-end;
    margin-top: 0;
    width: auto;
  }
`

const NameInput = styled(StyledInput)`
  border-color: ${colors.dark200};
  color: ${colors.dark500} !important;
  width: 100%;
  margin-bottom: 15px;

  &::placeholder {
    color: ${colors.dark300};
  }

  &:focus {
    border-color: ${colors.gray};
  }
`

const UserInput = styled(StyledInput)`
  border-color: ${colors.dark200};
  color: ${colors.dark500} !important;
  width: 100%;

  &::placeholder {
    color: ${colors.dark300};
  }

  &:focus {
    border-color: ${colors.gray};
  }

  &:disabled {
    background-color: ${colors.primaryCream};
    color: ${colors.dark400};
  }
`

const UserTextArea = styled.textarea`
  border: 1px solid #c4c4c4;
  border-color: ${colors.dark200};
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  height: 133px;
  padding: 0.75rem;
  resize: none;
  color: ${colors.dark500} !important;

  &::placeholder {
    color: ${colors.dark300};
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${colors.gray};
  }
`

const FormContainer = styled.div`
  width: 100%;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const ErrorContainer = styled.div`
  h4 {
    color: ${colors.primaryRed};
    margin-bottom: 15px;
  }
  p {
    line-height: 28px;
    white-space: pre-line;
  }
`
const UserLikesContainer = styled(Container)`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`

const ViewBY = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`

const ListDetailsIcon = styled.div`
  padding: 8px 20px;
  margin: 0 4px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
  & svg {
    width: 28px;
    height: 30px;
    path {
      transition: all 0.15s ease-in-out;
    }
  }
  &.active {
    background: #1296b4;
    svg path {
      fill: white;
    }
  }
  &:hover {
    opacity: 0.85;
  }
`
const ListImgIcon = styled.div`
  padding: 8px 20px;
  margin: 0 4px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 8px;
  & svg {
    width: 30px;
    height: 30px;
    path {
      transition: all 0.15s ease-in-out;
    }
  }
  &.active {
    background: #1296b4;
    svg path {
      fill: white;
    }
  }
  &:hover {
    opacity: 0.85;
  }
`
const ItemDetails = styled.div`
  display: none;
  position: relative;
  transition: all 0.09s ease-in-out;
  &.active {
    display: flex;
    background: ${colors.primaryCream};
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 8px;
    z-index: 99;
    flex-direction: column;

    @media ${device.tablet} {
      flex-direction: row;
    }
  }
`
const LargerImgView = styled.img`
  margin-right: 20px;
  object-fit: cover;
  order: 1;
  height: auto;
  width: 100%;

  @media ${device.tablet} {
    order: 2;
    height: 424px;
    width: 350px;
  }
`
const ActivePointer = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #f5f4f0;
    border-bottom-width: 22px;
    border-top: 0;
    margin-left: -10px;
    margin-top: -20px;
  }
`
const ImgThumbs = styled.div`
  display: flex;
  max-height: 424px;
  min-width: 52px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: row;
  order: 2;
  margin-right: 0;
  width: 100%;
  margin-top: 14px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    flex-direction: column;
    order: 1;
    margin-right: 16px;
    width: auto;
    margin-top: 0;
  }

  img {
    width: 52px;
    height: 52px;
    min-height: 52px;
    max-height: 52px;
    min-width: 52px;
    max-width: 52px;
    object-fit: cover;
    margin-right: 10px;
    margin-bottom: 0;
    border: 2px solid transparent;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: 0.8;
    }
    &.active {
      border-color: ${colors.primaryGreen};
    }

    @media ${device.tablet} {
      margin-bottom: 10px;
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
const InfoContainer = styled.div`
  width: 100%;
  max-width: unset;
  order: 3;
  margin-top: 20px;

  @media ${device.tablet} {
    max-width: 480px;
    margin-top: 0;
  }
`

const ActiveTitle = styled.div`
  font-family: Gidole;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.darkPrimary};
  margin-bottom: 10px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }
`
const ActiveDesc = styled.div`
  font-family: Gidole;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.dark};
  word-break: break-word;
`
const LinkSource = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  width: 100%;
  max-width: 100%;
  background: #f3fffd;
  border: 1px solid ${colors.primaryBlue};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  transition: all 0.15s ease-in-out;
  margin-top: 21px;

  @media ${device.tablet} {
    max-width: 230px;
    margin-top: 26px;
  }

  &:hover,
  &:focus {
    background: #e6f9f6;
  }

  div {
    margin: 0;
    span {
      font-family: Gidole;
      font-size: 12px;
      line-height: 24px;
      color: ${colors.primaryRed};
    }
  }
`
const LinkContainer = styled.div`
  text-align: right;
  p {
    font-family: Gidole;
    font-size: 12px;
    line-height: 24px;
    color: ${colors.dark};
    margin: 0;
    margin-top: 2px;
  }
`
const Price = styled.div`
  padding: 18px;
  & p {
    font-family: Gidole;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${colors.darkPrimary};
    margin: 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 9px;
  }
  & span {
    font-family: Gidole;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${colors.primaryRed};
  }
`
const CustomCardBody = styled(CardBody)`
  padding: 0;
`

const ActivePrice = styled.div`
  font-family: Gidole;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.darkPrimary};
`

const LoadBtn = styled(Button)`
  border-color: #1296b4 !important;
  background-color: #1296b4 !important;
  box-shadow: none !important;
  color: white;
  font-family: Gidole;
  font-size: 16px;
  line-height: 18px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 11px;
  max-width: 220px;
  width: 100%;
  display: block;
  margin: 15px auto;
  transition: all 0.15s ease-in-out;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`

const CustomRow = styled(Row)`
  margin-top: 20px;

  @media ${device.laptop} {
    margin-top: 36px;
  }
`
const CustomCol = styled(Col)`
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (max-width: 600px) {
    padding-left: 4px;
    padding-right: 4px;
  }
`
const CustomCard = styled(Card)`
  position: relative;
  background: #ffffff;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(58, 52, 51, 0.08);
  border-radius: 8px;
  margin-bottom: 26px;
  padding: 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 16px rgba(58, 52, 51, 0.16);
  }
  &.active {
    box-shadow: 0 0 0 2px ${colors.primaryGreen};
  }
`
const CustomCardImg = styled(CardImg)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #f5f4f0;
  height: 200px;
  object-fit: cover;
  &.img-only {
    height: 260px;
  }

  @media ${device.laptopL} {
    height: 300px;
    &.img-only {
      height: 360px;
    }
  }
`
const CustomCardText = styled(CardText)`
  padding: 18px;
  border-bottom: 1px solid #f5f4f0;
  margin: 0;
  &.img-only {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    padding: 9px;
  }
  & span {
    display: block;
    font-family: Gidole;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.darkPrimary};
    height: 48px;
    margin: 0;
    overflow: hidden;
  }
`

const LikeIcon = styled.img`
  width: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  filter: drop-shadow(1px 1px 6px #3a343360);
  &:hover {
    filter: brightness(0.94);
  }

  &.saving {
    cursor: progress;
  }
`
const EmptyLikes = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  img {
    width: 30px;
    height: auto;
    margin-bottom: 15px;
  }
  p {
    color: ${colors.dark400};
  }
`
const IndexLink = styled.a`
  transition: all 0.14s ease-in-out;
  color: ${colors.primaryGreen};
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${colors.primaryGreen};
    text-decoration: none;
    border-bottom: 1px solid;
  }
`
