import React from 'react'
import { Container } from 'reactstrap'

import { SiteType } from 'components/Filters/components/SiteFilter'
import { SortTypes } from 'components/Filters/components/SortBox'
import { PriceRange } from 'components/Filters/components/PriceFilter'

import Typography from 'components/TypographyV2'
import { SortBox, PriceFilter, SiteFilter } from 'components/Filters'
import Categories from 'components/categories'

interface SideBarContentProps {
  categories: string[]
  categoryCounts: any[]
  sites: SiteType[]
  isLoading: boolean
  setSelectedCategories: (categories: any[]) => void
  priceRangeFilter: PriceRange
  setPriceRangeFilter: (range: PriceRange) => void
  filterSites: (siteObject: SiteType, isActive: boolean) => void
}

const SideBarContent = ({
  categories,
  categoryCounts,
  sites,
  isLoading,
  setSelectedCategories,
  priceRangeFilter,
  setPriceRangeFilter,
  filterSites,
}: SideBarContentProps) => (
  <Container
    fluid
    className="overflow-auto vh-100"
    style={{ paddingBottom: '8rem' }}
  >
    <Typography.Headline>Filters</Typography.Headline>
    <div className="my-2" />
    <div className="my-4">
      <Categories
        categories={categories}
        isLoading={isLoading}
        counts={categoryCounts}
        onCategoriesSelectedChange={setSelectedCategories}
      />
    </div>
    <Typography.Title>Price</Typography.Title>
    <div className="mt-2 mb-4">
      <PriceFilter
        priceRange={priceRangeFilter}
        onChangePriceRange={setPriceRangeFilter}
      />
    </div>
    <Typography.Title>Sites</Typography.Title>
    <div className="my-2">
      <SiteFilter siteOptions={sites} onCheckboxToggle={filterSites} />
    </div>
  </Container>
)

export default SideBarContent
