import firebase from 'firebase'
import { ListingType } from 'hooks/useCrawlers'

/**
 * Add new likes
 *
 * @param {*} ListingType
 */
export const addItemToLikesFirestore = (item: ListingType) => {
  if (firebase.auth().currentUser !== null) {
    const { uid } = firebase.auth().currentUser
    firebase
      .firestore()
      .collection('Users')
      .doc(uid)
      .update({ likes: firebase.firestore.FieldValue.arrayUnion(item) })
  }
}

/**
 * Remove from likes
 *
 * @param {*} ListingType
 */
export const removeItemFromLikesFirestore = (item: ListingType) => {
  if (firebase.auth().currentUser !== null) {
    const { uid } = firebase.auth().currentUser
    firebase
      .firestore()
      .collection('Users')
      .doc(uid)
      .update({ likes: firebase.firestore.FieldValue.arrayRemove(item) })
  }
}
