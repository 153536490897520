import { colors } from 'config/constants'
import React from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'

const SitesMarqueeSection = () => (
  <StyledContainer fluid className="px-0 py-3 marquee">
    <StyledText className="track">
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
      eBay Depop Vinted BackMarket Oxfam Vinterior BeyondRetro Thriftify
      CashConverters CEX Preloved Etsy MusicMagpie Freecycle Hewi Gumtree Haru
    </StyledText>
  </StyledContainer>
)

const StyledText = styled.span`
  font-family: 'DIN 2014';
  font-weight: 800;
  font-style: italic;
  font-size: 24px;
  white-space: nowrap;
  color: ${colors.carribeanGreen};
`

const StyledContainer = styled(Container)`
  width: 100%;
  overflow: hidden;
  background-color: ${colors.foam};
  border-bottom: 1px solid black;
  border-top: 2.5px solid black;
  @media only screen and (max-width: 980px) {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  } ;
`

export default SitesMarqueeSection
