/* eslint-disable */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'

import { colors } from 'config/constants'
import useBreakpoint, { PHONE_BREAKPOINTS } from 'hooks/useBreakPoint'

interface ExpandedProductCardProps {
  data: any
}

const ExpandedProductCard = ({ data }: ExpandedProductCardProps) => {
  const br = useBreakpoint()
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    if (data) {
      setSelectedImage(data.images[0])
    }
  }, [data])

  return (
    <div className="d-flex flex-column flex-md-row p-3 bg-white">
      <div>
        <img
          src={selectedImage ?? data?.image[0]}
          alt={data?.title}
          height={355}
          width={PHONE_BREAKPOINTS.includes(br) ? '100%' : 265}
          style={{ objectFit: 'contain', backgroundColor: 'black' }}
        />
      </div>
      <div className="d-flex flex-row flex-md-column flex-wrap pl-0 pl-md-3">
        {data?.images?.map((imgSrc, index) => (
          <img
            onClick={() => {
              setSelectedImage(imgSrc)
            }}
            src={imgSrc}
            alt="pic"
            height={50}
            width={50}
            className="mt-3 mr-2 mr-md-0"
            style={
              selectedImage === imgSrc
                ? {
                    objectFit: 'cover',
                    border: `2px solid ${colors.carribeanGreen}`,
                  }
                : { objectFit: 'cover' }
            }
          />
        ))}
      </div>
      <div className="pl-0 pl-md-3 pt-3">
        <StyledTitle>{data?.title}</StyledTitle>
        <div className="d-flex align-items-center">
          <StyledPrice>{`£${data?.price}`}</StyledPrice>
          <span className="px-1">·</span>
          <StyledSiteName>
            {data?.siteName ? data?.siteName : data?.site}
          </StyledSiteName>
          <StyledButton
            href={data?.url}
            target="_blank"
            role="button"
            className="btn d-flex align-items-center"
          >
            <span style={{ fontSize: '12px' }}>Visit Site</span>
            <FeatherIcon
              icon="external-link"
              size={14}
              style={{ color: '#969BAB', marginLeft: '0.5rem' }}
            />
          </StyledButton>
        </div>
        <StyledParagraph className="mb-0 mt-2 mt-md-3">
          {data?.description}
        </StyledParagraph>
      </div>
    </div>
  )
}

const StyledTitle = styled.h4`
  font-family: 'DIN 2014';
  font-size: 18px;
  font-weight: 700;
`

const StyledPrice = styled.span`
  display: block;
  font-family: 'DIN 2014';
  font-weight: 800;
  font-size: 14px;
  color: ${colors.carribeanGreen};
  margin-top: 2px;
`

const StyledSiteName = styled.span`
  display: block;
  color: #f15b3b;
  font-family: 'DIN 2014'
  color: #f15b3b;
  font-size: 14px;
`

const StyledButton = styled.a`
font-weight: 400;
font-style: italic;
font-family: 'DIN 2014'
font-size: 12px;
color: #969BAB;
text-decoration: none;
border: 1px solid #EEEFF4;
border-radius: 5px;
margin-left: 0.5rem
`

const StyledParagraph = styled.p`
  font-weight: 400;
  font-family: Gidole;
  font-size: 14px;
  font-color: #887f7c;
`

export default ExpandedProductCard
