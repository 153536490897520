import styled from 'styled-components'
import { Input } from 'reactstrap'

const StyledInput = styled(Input)`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #c4c4c4;
  }
`

export default StyledInput
