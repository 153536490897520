import FeatherIcon from 'feather-icons-react'
import useBreakpoint, {
  Breakpoint,
  SMALLER_BREAKPOINTS,
} from 'hooks/useBreakPoint'
import { useContainerDimensions } from 'hooks/useContainerDimensions'
import useDebouncedCallback from 'hooks/useDebouncedCallback'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface SearchBoxProps {
  onSearch?: (searchValue: string) => void
  type?: 'light' | 'dark'
  showGradient?: boolean
  value?: string
}

// TODO: fix styles
const SearchBox = ({
  onSearch = () => {},
  type = 'dark',
  showGradient = false,
  value = '',
}: SearchBoxProps) => {
  const ref = useRef()
  const br = useBreakpoint()
  const [searchValue, setSearchValue] = useState(value)

  const debounceOnSearch = useDebouncedCallback(onSearch, 1000)
  const { width } = useContainerDimensions(ref)

  const handleSearchValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.currentTarget.value)
    },
    []
  )

  const clearSearchValue = () => setSearchValue('')

  const onSubmit = useCallback(() => {
    if (searchValue !== '') {
      debounceOnSearch(searchValue)
    }
  }, [debounceOnSearch, searchValue])

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        if (searchValue !== '') {
          debounceOnSearch(searchValue)
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [debounceOnSearch, searchValue])

  return (
    <form onSubmit={onSubmit}>
      <ContainerStyle ref={ref} type={type} breakPoint={br}>
        <input
          className="mainSearch"
          type="text"
          placeholder="Search for anything"
          value={searchValue}
          onChange={handleSearchValueChange}
          style={{
            border: '0',
            width: '100%',
            outline: 'none',
            marginTop: 6,
            marginBottom: 6,
          }}
        />
        <div
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
          onClick={clearSearchValue}
        >
          <FeatherIcon
            icon={searchValue.length > 0 ? 'x' : 'search'}
            size={16}
            style={{
              color: searchValue.length > 0 ? '#FF0000' : '#000',
            }}
          />
        </div>
      </ContainerStyle>
      {showGradient && <RainbowDiv style={{ width: width }} />}
    </form>
  )
}

// @TODO FIXME the width does not respect the parent/sibling
const ContainerStyle = styled.div<{
  type?: 'light' | 'dark'
  breakPoint: Breakpoint
}>`
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: ${(props) => (props.type === 'dark' ? 'none' : '1px solid black')};
  z-index: 1;
  width: 100%;
  padding: 8px 14px 8px 13px;
  height: 44px;
  ${(props) =>
    SMALLER_BREAKPOINTS.includes(props.breakPoint) &&
    'box-shadow: 0px 2px 0px #18191F'};
`

// @TODO FIXME the width does not respect the parent/sibling
const RainbowDiv = styled.div`
  position: absolute;
  background: linear-gradient(
      270deg,
      #f15b3b 0%,
      #54a086 20.65%,
      #00c6ae 31.75%,
      #ffbd12 53.97%,
      #ff89bb 77.79%,
      #1947e6 101.6%
    ),
    #ffffff;
  border-radius: 10px;
  height: 35px;
  margin: -30px 0px;
  width: 90%;
  z-index: 0;
  overflow: hidden;
`

export default SearchBox
