import imgPlaceholder from 'assets/img/placeholder.png'

export const getDepopTitle = (slug) => {
  const indexOfH = slug.indexOf('-')
  const titleRaw = slug.substring(indexOfH + 1, slug.length)
  return titleRaw.replace(/-/g, ' ')
}

export const missingImagesFiltered = (imageProp) => {
  if (imageProp.length > 0) {
    const imgsOnly = []
    imageProp.forEach((img) => {
      if (img.url_570xN) imgsOnly.push(img.url_570xN)
    })
    return imgsOnly
  }
  return imgPlaceholder
}

export const tnmissingImagesFiltered = (listing) => {
  if (listing.photos && listing.photos.length > 0) {
    const imgsOnly = []
    listing.photos.forEach((img) => {
      if (img.url) imgsOnly.push(img.url)
    })
    return imgsOnly
  }
  return imgPlaceholder
}

export const eBayMissingImagesFiltered = (listing) => {
  if (
    ('image' in listing || 'imageUrl' in listing) &&
    'additionalImages' in listing
  ) {
    const imgsOnly = listing.additionalImages.map((img) => {
      const imgURL = img.imageUrl.replace('/225x225', '')
      return imgURL
    })
    return [listing.thumbnailImages[0].imageUrl, ...imgsOnly]
  }

  if ('image' in listing || 'imageUrl' in listing) {
    return listing.thumbnailImages[0].imageUrl
  }
  return imgPlaceholder
}

export const ebayPriceParsed = (listing) => {
  if (listing.price.currency === 'USD') {
    return listing.price.convertedFromValue
      ? parseFloat(listing.price.convertedFromValue)
      : parseFloat(listing.price.value)
  }
  return parseFloat(listing.price.value)
}

export const shuffleArray = (array) => {
  const newArray = array
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = newArray[currentIndex]
    newArray[currentIndex] = newArray[randomIndex]
    newArray[randomIndex] = temporaryValue
  }

  return newArray
}

export const trimGumtreePrice = (price) => parseFloat(price.replace('£', ''))
export const generateUserName = (fullname) =>
  fullname.toLowerCase().replace(/ /g, '')

export const chunk = (arr: any[], size: number): any =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
